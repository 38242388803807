/**======================================================================
=========================================================================
Template Name: Datta Able Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
/**  =====================
     Google Font
==========================  **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600");
@import url("../plugins/bootstrap/css/bootstrap.min.css");
@import url("../fonts/feather/css/feather.css");
@import url("../plugins/jquery-scrollbar/css/jquery.scrollbar.min.css");
@import url("../fonts/datta/datta-icon.css");
/**  =====================
      Custom css start
==========================  **/
body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #888;
  font-weight: 400;
  background: #f4f7fa;
  position: relative;
}

*:focus {
  outline: none;
}

a:hover {
  outline: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
  font-weight: 400;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 44px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 14px;
}

p {
  font-size: 14px;
}

strong {
  font-weight: 400;
}

.img-radius {
  border-radius: 50%;
}

.page-header-title + .breadcrumb {
  background: transparent;
  padding: 0;
}
.page-header-title + .breadcrumb > .breadcrumb-item a {
  color: #888;
}
.page-header-title + .breadcrumb > .breadcrumb-item:last-child a {
  color: #111;
  font-weight: 600;
}

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.theme-bg2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

/* ==========  card css start  =========== */
.anim-rotate {
  -webkit-animation: anim-rotate 1s linear infinite;
          animation: anim-rotate 1s linear infinite;
}

@-webkit-keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes anim-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.anim-close-card {
  -webkit-animation: anim-close-card 1.4s linear;
          animation: anim-close-card 1.4s linear;
}

@-webkit-keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
}
p.text-muted {
  font-size: 13px;
}

.card {
  border-radius: 0;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
          box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}
.card .card-header h5 {
  margin-bottom: 0;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}
.card .card-header h5:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: -25px;
  top: 0;
  width: 4px;
  height: 20px;
}
.card .card-header.borderless {
  border-bottom: none;
}
.card .card-header.borderless h5:after {
  display: none;
}
.card .card-header .card-header-right {
  right: 10px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 0;
  position: absolute;
}
@media only screen and (max-width: 575px) {
  .card .card-header .card-header-right {
    display: none;
  }
}
.card .card-header .card-header-right .dropdown-menu {
  margin-top: 0;
}
.card .card-header .card-header-right .dropdown-menu li a {
  font-size: 14px;
  text-transform: capitalize;
}
.card .card-header .card-header-right .btn.dropdown-toggle {
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #888;
}
.card .card-header .card-header-right .btn.dropdown-toggle i {
  margin-right: 0;
}
.card .card-header .card-header-right .btn.dropdown-toggle:after {
  display: none;
}
.card .card-header .card-header-right .btn.dropdown-toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
.card .card-footer {
  border-top: 1px solid #f1f1f1;
  background: transparent;
  padding: 25px;
}
.card .card-block,
.card .card-body {
  padding: 30px 25px;
}
.card.card-load {
  position: relative;
  overflow: hidden;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: #04a9f5;
  font-size: 24px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

/* ==========  scrollbar End  =========== */
.scroll-y {
  z-index: 1027;
}

/* ==========  scrollbar End  ===========
 ==========  card css End  ===========
 ================================    Dropdown Start  ===================== */
.dropdown-menu {
  padding: 20px 0;
  margin-top: 15px;
  -webkit-box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
          box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  border: none;
}
.dropdown-menu.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: 15px;
  top: -5px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu.show.dropdown-menu-right:before {
  left: auto;
  right: 5px;
}
.dropdown-menu[x-placement="top-start"] {
  margin-bottom: 15px;
  margin-top: 0;
}
.dropdown-menu[x-placement="top-start"].show:before {
  content: "\64";
  bottom: -5px;
  top: auto;
  text-shadow: 0 2px 2px rgba(69, 90, 100, 0.12);
}
.dropdown-menu > li {
  padding-left: 15px;
  padding-right: 15px;
}
.dropdown-menu > li > a {
  padding: 5px;
  color: #888;
}
.dropdown-menu > li > a i {
  font-size: 15px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
}
.dropdown-menu > li.active, .dropdown-menu > li:active, .dropdown-menu > li:focus, .dropdown-menu > li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.dropdown-menu > li.active > a, .dropdown-menu > li:active > a, .dropdown-menu > li:focus > a, .dropdown-menu > li:hover > a {
  background: transparent;
}

/* ================================    Dropdown End  ===================== */
@media (min-width: 1600px) {
  .container {
    max-width: 1540px;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
/* ========================================================
 ===============     document      ======================
 ========================================================

 Grid examples
*/
.bd-example-row {
  background: #f4f7fa;
  padding: 15px;
  margin-bottom: 15px;
}
.bd-example-row .row > [class^="col-"],
.bd-example-row .row > .col {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.bd-example-row .row + .row {
  margin-top: 1rem;
}
.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(255, 0, 0, 0.1);
}

.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(255, 0, 0, 0.1);
}

/* Docs examples */
.bd-example {
  position: relative;
  padding: 1rem;
  margin: 1rem;
  border: solid #ddd;
  border-width: 0.2rem 0 0;
}
@media only screen and (max-height: 575px) {
  .bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

/* Example modals */
.modal {
  z-index: 1072;
}
.modal .popover,
.modal .tooltip {
  z-index: 1073;
}

.modal-backdrop {
  z-index: 1071;
}

.bd-example-modal {
  background-color: #fafafa;
}
.bd-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bd-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}

/* Example tabbable tabs */
.bd-example-tabs .nav-tabs {
  margin-bottom: 1rem;
}

/* Code snippets */
.highlight {
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: #f3f2fb;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
@media only screen and (max-height: 575px) {
  .highlight {
    padding: 1.5rem;
  }
}

.bd-content .highlight {
  margin-right: 15px;
  margin-left: 15px;
}
@media only screen and (max-height: 575px) {
  .bd-content .highlight {
    margin-right: 0;
    margin-left: 0;
  }
}

.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}

/* =======================================================================
 ===============     Ui kit copy model style      ====================== */
/* Code examples */
.datta-example {
  position: relative;
}
.datta-example .datta-example-btns {
  position: absolute;
  right: 30px;
  top: -10px;
  opacity: 0;
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.datta-example .datta-example-btns .datta-example-btn {
  display: inline-block;
  line-height: 1;
  font-weight: 600;
  background: #04a9f5;
  color: #fff;
  padding: 0.1875rem 0.3125rem;
  border-radius: 2px;
  white-space: nowrap;
  font-size: 11px;
  margin: 0 4px;
}
.datta-example .datta-example-btns .datta-example-btn.copy::before {
  content: "COPY";
}
.datta-example .datta-example-btns .datta-example-btn.copied {
  background: #1de9b6 !important;
  color: #fff !important;
}
.datta-example .datta-example-btns .datta-example-btn.copied::before {
  content: "COPIED!";
}
.datta-example:hover .datta-example-btns {
  top: -21px;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  opacity: 1;
}

/* Modal */
.datta-example-modal {
  position: fixed;
  z-index: 1099;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #282c34;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.datta-example-modal-opened {
  overflow: hidden !important;
}
.datta-example-modal-opened .datta-example-modal {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.datta-example-modal-content {
  max-width: 100vw;
  margin: auto;
  padding: 50px;
  height: 100vh;
  overflow: auto;
}
.datta-example-modal-content > pre {
  overflow: hidden;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.datta-example-modal-content > pre > code {
  padding: 0;
  background: none;
  font-size: 16px;
}

.md-datta-example-modal-copy {
  display: block;
  position: fixed;
  top: 90px;
  right: 30px;
  margin-right: 8px;
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 10px 15px;
  background: #04a9f5;
  color: #fff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  white-space: nowrap;
}
.md-datta-example-modal-copy:hover {
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  color: #fff;
}
.md-datta-example-modal-copy.copied::before {
  content: "Copied to Clipboard Successfully ! . . .";
  position: absolute;
  display: block;
  right: 100%;
  margin-right: 10px;
  font-size: 14px;
  background: #1de9b6;
  line-height: 24px;
  height: 24px;
  border-radius: 3px;
  padding: 0 6px;
  top: 50%;
  margin-top: -12px;
}

.datta-example-modal-close {
  display: block;
  position: fixed;
  top: 10px;
  right: 52px;
  color: #fff;
  opacity: 0.2;
  font-size: 3rem;
  font-weight: 100;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.datta-example-modal-close:hover {
  color: #fff;
  opacity: 0.9;
}

/* Code */
.cui-bottom-spacer {
  height: 12rem;
}

/* editor style for model */
.hljs {
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.hljs-comment,
.hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: #c678dd;
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: #e06c75;
}

.hljs-literal {
  color: #56b6c2;
}

.hljs-addition,
.hljs-attribute,
.hljs-meta-string,
.hljs-regexp,
.hljs-string {
  color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
  color: #e6c07b;
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: #d19a66;
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: #61aeee;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.hljs-link {
  text-decoration: underline;
}

/* ================================    md animated mode start   ===================== */
.md-show.md-effect-12 ~ .md-overlay {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

/* ================================    md animated mode End   ===================== */
/* ================================    range slider Start  ===================== */
.tooltip.in {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

/* ================================    range slider End  ===================== */
/* ================================    owl-carousel slider Start  ===================== */
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px;
}

/* ================================    owl-carousel slider End  ===================== */
/* ================================    Bootstrap tags input Start  ===================== */
.bootstrap-tagsinput {
  padding: 10px 15px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 2px;
  background: #f0f3f6;
}
.bootstrap-tagsinput .tag {
  padding: 5px 12px;
  border-radius: 2px;
  line-height: 37px;
  margin-top: 5px;
  margin-right: 5px;
}
.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-right: -5px;
}
.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "\e8f6";
  padding: 0 2px;
  font-family: 'feather' !important;
}

/* ================================    Bootstrap tags input End  ===================== */
/* ================================    Multi-Select Start  ===================== */
.ms-container {
  width: 100%;
}

/* ================================    Multi-Select End  ===================== */
/* ================================    Blockquote Start  ===================== */
.blockquote {
  border-left: 0.25rem solid #eaeaea;
  padding: 0.5rem 1rem;
}
.blockquote.text-right {
  border-left: none;
  border-right: 0.25rem solid #eaeaea;
}

/* ================================    Blockquote End  ===================== */
/* ================================    animation start  ===================== */
.card .animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  border: 5px solid #fff;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ================================    animation End  ===================== */
/* ================================    browser  warning  Start  ===================== */
.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}
.ie-warning p {
  font-size: 17px;
}
.ie-warning h1 {
  color: #fff;
}
.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}
.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}
.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}
.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}
.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}
.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

/* ================================    browser  warning  End  ===================== */
/* material icon for material datetime picker */
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2") format("woff2");
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* material icon for material datetime picker */
/**  =====================
      Generic-class css start
========================== **/
/*====== Padding , Margin css starts ======*/
.p-0 {
  padding: 0px;
}
.p-t-0 {
  padding-top: 0px;
}
.p-b-0 {
  padding-bottom: 0px;
}
.p-l-0 {
  padding-left: 0px;
}
.p-r-0 {
  padding-right: 0px;
}

.m-0 {
  margin: 0px;
}
.m-t-0 {
  margin-top: 0px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-r-0 {
  margin-right: 0px;
}

.p-5 {
  padding: 5px;
}
.p-t-5 {
  padding-top: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.p-l-5 {
  padding-left: 5px;
}
.p-r-5 {
  padding-right: 5px;
}

.m-5 {
  margin: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-l-5 {
  margin-left: 5px;
}
.m-r-5 {
  margin-right: 5px;
}

.p-10 {
  padding: 10px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-b-10 {
  padding-bottom: 10px;
}
.p-l-10 {
  padding-left: 10px;
}
.p-r-10 {
  padding-right: 10px;
}

.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-15 {
  padding: 15px;
}
.p-t-15 {
  padding-top: 15px;
}
.p-b-15 {
  padding-bottom: 15px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-r-15 {
  padding-right: 15px;
}

.m-15 {
  margin: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-r-15 {
  margin-right: 15px;
}

.p-20 {
  padding: 20px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-20 {
  padding-left: 20px;
}
.p-r-20 {
  padding-right: 20px;
}

.m-20 {
  margin: 20px;
}
.m-t-20 {
  margin-top: 20px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-l-20 {
  margin-left: 20px;
}
.m-r-20 {
  margin-right: 20px;
}

.p-25 {
  padding: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-r-25 {
  padding-right: 25px;
}

.m-25 {
  margin: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-b-25 {
  margin-bottom: 25px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-r-25 {
  margin-right: 25px;
}

.p-30 {
  padding: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-r-30 {
  padding-right: 30px;
}

.m-30 {
  margin: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-r-30 {
  margin-right: 30px;
}

.p-35 {
  padding: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-r-35 {
  padding-right: 35px;
}

.m-35 {
  margin: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-r-35 {
  margin-right: 35px;
}

.p-40 {
  padding: 40px;
}
.p-t-40 {
  padding-top: 40px;
}
.p-b-40 {
  padding-bottom: 40px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-r-40 {
  padding-right: 40px;
}

.m-40 {
  margin: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-r-40 {
  margin-right: 40px;
}

.p-45 {
  padding: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-r-45 {
  padding-right: 45px;
}

.m-45 {
  margin: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-r-45 {
  margin-right: 45px;
}

.p-50 {
  padding: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-r-50 {
  padding-right: 50px;
}

.m-50 {
  margin: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-r-50 {
  margin-right: 50px;
}

/*====== Padding , Margin css ends ======*/
/*====== text-color, background color css starts ======*/
.bg-c-blue {
  background: #04a9f5;
}

.text-c-blue {
  color: #04a9f5;
}

.bg-c-red {
  background: #f44236;
}

.text-c-red {
  color: #f44236;
}

.bg-c-green {
  background: #1de9b6;
}

.text-c-green {
  color: #1de9b6;
}

.bg-c-yellow {
  background: #f4c22b;
}

.text-c-yellow {
  color: #f4c22b;
}

.bg-c-purple {
  background: #a389d4;
}

.text-c-purple {
  color: #a389d4;
}

/*====== text-color css ends ======*/
/*====== Card top border css starts ======*/
.card-border-c-blue {
  border-top: 4px solid #04a9f5;
}

.card-border-c-red {
  border-top: 4px solid #f44236;
}

.card-border-c-green {
  border-top: 4px solid #1de9b6;
}

.card-border-c-yellow {
  border-top: 4px solid #f4c22b;
}

.card-border-c-purple {
  border-top: 4px solid #a389d4;
}

/*====== Card top border ends ======*/
/*====== Font-size css starts ======*/
.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-200 {
  font-weight: 200;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/* new logo start */
.b-brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.b-brand .b-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 10px;
  width: 35px;
  height: 35px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.b-brand .b-bg i {
  color: #fff;
}
.b-brand .b-title {
  margin-left: 10px;
  font-weight: 100;
  color: #fff;
  font-size: 18px;
}

.navbar-collapsed .b-brand .b-title {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed .mobile-menu {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-collapsed:hover .b-brand .b-title {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.navbar-collapsed:hover .mobile-menu {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}

/* new logo End  */
/* Pre-loader css start */
.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
  content: '';
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}
.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

@-webkit-keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
/* Pre-loader css end */
/* header css start */
.pcoded-header {
  z-index: 1028;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 70px;
  padding: 0;
  position: relative;
  top: 0;
  background: transparent;
  color: #3f4d67;
  width: calc(100% - 264px);
  margin-left: 264px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .m-header {
  display: none;
}
.pcoded-header .m-header .logo-dark,
.pcoded-header .m-header .logo-thumb {
  display: none;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text {
  margin-right: 0;
}
.pcoded-header .input-group {
  background: transparent;
}
.pcoded-header .input-group .input-group-text,
.pcoded-header a,
.pcoded-header dropdown-toggle {
  color: #3f4d67;
}
.pcoded-header .input-group .input-group-text:hover,
.pcoded-header a:hover,
.pcoded-header dropdown-toggle:hover {
  color: #04a9f5;
}
.pcoded-header #mobile-header {
  display: none;
}
.pcoded-header .navbar-nav {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: inline-block;
}
.pcoded-header .navbar-nav > li {
  line-height: 70px;
  display: inline-block;
  padding: 0 12px;
}
.pcoded-header .navbar-nav > li .nav-link {
  padding: 0;
}
.pcoded-header .navbar-nav > li:first-child {
  padding-left: 25px;
}
.pcoded-header .navbar-nav > li:last-child {
  padding-right: 40px;
}
.pcoded-header .mr-auto .dropdown-menu {
  margin-left: -20px;
}
.pcoded-header .ml-auto {
  float: right;
}
.pcoded-header .ml-auto .dropdown-menu {
  margin-right: -20px;
}
.pcoded-header .main-search .input-group {
  border-radius: 20px;
  padding: 0;
}
.pcoded-header .main-search .input-group .form-control,
.pcoded-header .main-search .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.pcoded-header .main-search .input-group .search-close {
  display: none;
}
.pcoded-header .main-search .input-group .search-btn {
  border-radius: 50%;
  padding: 0;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-header .main-search .input-group .form-control {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
  font-size: 14px;
}
.pcoded-header .main-search .input-group .form-control:active, .pcoded-header .main-search .input-group .form-control:focus, .pcoded-header .main-search .input-group .form-control:hover,
.pcoded-header .main-search .input-group .search-btn:active,
.pcoded-header .main-search .input-group .search-btn:focus,
.pcoded-header .main-search .input-group .search-btn:hover {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-header .main-search.open .input-group {
  background: #fff;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
          box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
  padding: 5px 5px 5px 20px;
}
.pcoded-header .main-search.open .input-group .search-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pcoded-header .main-search.open .input-group .search-btn {
  padding: 5px;
  margin-left: 5px;
  background: #04a9f5;
  border-color: #04a9f5;
}
.pcoded-header .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header .main-search.open .input-group .form-control {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
}
.pcoded-header .main-search.open .input-group .form-control:active, .pcoded-header .main-search.open .input-group .form-control:focus, .pcoded-header .main-search.open .input-group .form-control:hover,
.pcoded-header .main-search.open .input-group .search-btn:active,
.pcoded-header .main-search.open .input-group .search-btn:focus,
.pcoded-header .main-search.open .input-group .search-btn:hover {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-header .dropdown .dropdown-toggle {
  line-height: 70px;
  display: inline-block;
  padding-right: 15px;
}
.pcoded-header .dropdown .dropdown-toggle:after {
  content: "\e842";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 0;
  right: 15px;
}
.pcoded-header .dropdown .dropdown-menu {
  position: absolute;
  border: none;
  margin-top: -6px;
  min-width: 290px;
}
.pcoded-header .dropdown .dropdown-menu.show:before {
  display: none;
}
.pcoded-header .dropdown .dropdown-menu li {
  line-height: 1.2;
}
.pcoded-header .dropdown .dropdown-menu li a {
  padding: 10px;
  font-size: 14px;
}
.pcoded-header .dropdown .dropdown-menu li.active a, .pcoded-header .dropdown .dropdown-menu li:active a, .pcoded-header .dropdown .dropdown-menu li:focus a, .pcoded-header .dropdown .dropdown-menu li:hover a {
  color: #888;
}
.pcoded-header .dropdown.show:before {
  content: "\63";
  font-family: "pct";
  position: absolute;
  left: -5px;
  top: 60px;
  z-index: 1001;
  font-size: 40px;
  line-height: 0;
  color: #fff;
  text-shadow: 0 -2px 2px rgba(65, 76, 78, 0.12);
}
.pcoded-header .dropdown .notification {
  width: 350px;
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .notification .noti-head {
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 20px;
}
.pcoded-header .dropdown .notification .noti-head a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body {
  padding: 0;
}
.pcoded-header .dropdown .notification .noti-body img {
  width: 40px;
  margin-right: 20px;
}
.pcoded-header .dropdown .notification .noti-body li {
  padding: 15px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-header .dropdown .notification .noti-body li.n-title {
  padding-bottom: 0;
}
.pcoded-header .dropdown .notification .noti-body li.n-title p {
  margin-bottom: 5px;
}
.pcoded-header .dropdown .notification .noti-body li.notification:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .notification .noti-body li p {
  margin-bottom: 5px;
  font-size: 13px;
}
.pcoded-header .dropdown .notification .noti-body li p strong {
  color: #222;
}
.pcoded-header .dropdown .notification .noti-body li .n-time {
  font-size: 80%;
  float: right;
}
.pcoded-header .dropdown .notification .noti-footer {
  border-top: 1px solid #f1f1f1;
  padding: 15px 20px;
  text-align: center;
}
.pcoded-header .dropdown .notification .noti-footer a {
  text-decoration: underline;
  font-size: 13px;
}
.pcoded-header .dropdown .notification ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .notification ul li {
  padding: 20px 15px;
}
.pcoded-header .dropdown .profile-notification {
  padding: 0;
  line-height: 1.4;
  overflow: hidden;
}
.pcoded-header .dropdown .profile-notification .pro-head {
  color: #fff;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px;
  position: relative;
  background: #04a9f5;
}
.pcoded-header .dropdown .profile-notification .pro-head img {
  width: 40px;
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
  padding-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.pcoded-header .dropdown .profile-notification .pro-body {
  padding: 20px 0;
  margin-bottom: 0;
  list-style: none;
}
.pcoded-header .dropdown .profile-notification .pro-body li a {
  color: #888;
  font-size: 14px;
  padding: 10px 20px;
}
.pcoded-header .dropdown .profile-notification .pro-body li a i {
  margin-right: 10px;
}
.pcoded-header .dropdown .profile-notification .pro-body li.active, .pcoded-header .dropdown .profile-notification .pro-body li:active, .pcoded-header .dropdown .profile-notification .pro-body li:focus, .pcoded-header .dropdown .profile-notification .pro-body li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header .dropdown .profile-notification .pro-body li.active > a, .pcoded-header .dropdown .profile-notification .pro-body li:active > a, .pcoded-header .dropdown .profile-notification .pro-body li:focus > a, .pcoded-header .dropdown .profile-notification .pro-body li:hover > a {
  background: transparent;
}
.pcoded-header .dropdown.drp-user.show:before {
  color: #04a9f5;
}

/* header css end */
/* menu[ vartical ] css start */
.mobile-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 10px;
}
.mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after, .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.mobile-menu.on span {
  background-color: transparent;
}
.mobile-menu.on span:after, .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.mobile-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
          transform: rotate(45deg) translate(4px, 4px);
}
.mobile-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
          transform: rotate(-45deg) translate(3px, -3px);
}

.navbar-brand {
  background: #3f4d67;
}

.pcoded-header:before,
.pcoded-main-container:before {
  content: "";
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pcoded-navbar {
  display: block;
  z-index: 1029;
  position: fixed;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 1px 0 20px 0 #3f4d67;
          box-shadow: 1px 0 20px 0 #3f4d67;
  width: 264px;
  height: 100vh;
  top: 0;
  background: #3f4d67;
  color: #a9b7d0;
}
.pcoded-navbar ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}
.pcoded-navbar .scroll-div.navbar-content {
  height: calc(100vh - 70px);
}
.pcoded-navbar .header-logo {
  position: relative;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  float: left;
  height: 70px;
  text-align: center;
  width: 264px;
  margin-right: 0;
  padding: 10px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar .header-logo .logo-thumb {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  opacity: 0;
  position: absolute;
  -webkit-transition: unset;
  transition: unset;
}
.pcoded-navbar .header-logo + .scroll-div {
  float: left;
  display: inline-block;
}
.pcoded-navbar .mobile-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 37px;
  height: 70px;
  position: absolute;
  right: 10px;
  top: 0;
  padding: 0 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar .mobile-menu span {
  position: relative;
  display: block;
  width: 100%;
  height: 2.8px;
  background-color: #a9b7d0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after, .pcoded-navbar .mobile-menu span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 2.8px;
  background-color: #a9b7d0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 2px;
}
.pcoded-navbar .mobile-menu span:after {
  top: 5px;
  width: 70%;
}
.pcoded-navbar .mobile-menu span:before {
  top: -5px;
  width: 40%;
}
.pcoded-navbar .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar .mobile-menu.on span:after, .pcoded-navbar .mobile-menu.on span:before {
  height: 2px;
  width: 100%;
}
.pcoded-navbar .mobile-menu.on span:before {
  -webkit-transform: rotate(45deg) translate(4px, 4px);
          transform: rotate(45deg) translate(4px, 4px);
}
.pcoded-navbar .mobile-menu.on span:after {
  -webkit-transform: rotate(-45deg) translate(3px, -3px);
          transform: rotate(-45deg) translate(3px, -3px);
}
.pcoded-navbar .pcoded-badge {
  font-size: 75%;
  position: absolute;
  right: 30px;
  top: 12px;
  padding: 2px 7px;
  border-radius: 2px;
}
.pcoded-navbar .pcoded-inner-navbar {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.pcoded-navbar .pcoded-inner-navbar li {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a {
  text-align: left;
  padding: 7px 15px;
  margin: 5px 0 0;
  display: block;
  border-radius: 0;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a .pcoded-mtext {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon {
  font-size: 1rem;
  padding: 4px 7px;
  margin-right: 7px;
  border-radius: 4px;
  width: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
  text-align: center;
}
.pcoded-navbar .pcoded-inner-navbar li > a > .pcoded-micon + .pcoded-mtext {
  position: absolute;
  top: 11px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e844";
  font-family: 'feather';
  font-size: 15px;
  border: none;
  position: absolute;
  top: 11px;
  right: 20px;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > .pcoded-submenu {
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger.active > a {
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a {
  text-align: left;
  padding: 7px 7px 7px 60px;
  margin: 0;
  display: block;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 16px;
  left: 35px;
  width: 5px;
  height: 5px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a {
  padding: 7px 7px 7px 80px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li .pcoded-submenu > li > a:before {
  left: 55px;
}
.pcoded-navbar .pcoded-inner-navbar li.pcoded-menu-caption {
  font-size: 10px;
  font-weight: 600;
  padding: 25px 20px 5px;
  text-transform: uppercase;
  position: relative;
}
.pcoded-navbar .pcoded-inner-navbar li.disabled > a {
  cursor: default;
  opacity: 0.5;
}
.pcoded-navbar .pcoded-submenu {
  background: #39465e;
  padding: 15px 0;
}
.pcoded-navbar a {
  color: #a9b7d0;
}
.pcoded-navbar .navbar-content,
.pcoded-navbar .navbar-wrapper {
  width: 100%;
  height: 100%;
}
.pcoded-navbar.navbar-collapsed {
  width: 80px;
  height: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .header-logo {
  width: 80px;
}
.pcoded-navbar.navbar-collapsed .header-logo img {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: unset;
  transition: unset;
}
.pcoded-navbar.navbar-collapsed .header-logo .logo-thumb {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 1;
  left: calc((80px / 2) - 20px);
}
.pcoded-navbar.navbar-collapsed .header-logo .mobile-menu {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed .navbar-content.ps {
  overflow: visible;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption {
  position: relative;
  width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 25px;
  left: 15px;
  width: calc(100% - 30px);
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  z-index: 1026;
  padding: 7px 25px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  right: 12px;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu {
  display: none;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  content: "";
  position: absolute;
  top: 5px;
  left: calc(calc(80px / 2) - 3px);
  width: 2px;
  height: calc(100% - 5px);
  background: rgba(169, 183, 208, 0.1);
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: transparent;
  white-space: nowrap;
}
.pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed ~ .pcoded-header {
  width: calc(100% - 80px);
}
.pcoded-navbar.navbar-collapsed ~ .pcoded-header,
.pcoded-navbar.navbar-collapsed ~ .pcoded-main-container {
  margin-left: 80px;
}
.pcoded-navbar.navbar-collapsed .pcoded-badge {
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  opacity: 0;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.pcoded-navbar.navbar-collapsed:hover {
  width: 264px !important;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo {
  width: 264px;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo img {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo .logo-thumb {
  -webkit-transform: rotateY(-90deg);
          transform: rotateY(-90deg);
  opacity: 0;
}
.pcoded-navbar.navbar-collapsed:hover .header-logo .mobile-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 10px;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption > label {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}
.pcoded-navbar.navbar-collapsed:hover .mobile-menu {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  opacity: 1;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li > a > .pcoded-mtext:after {
  opacity: 1;
  visibility: visible;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a:after {
  -webkit-transform: rotateX(0deg) rotate(90deg);
          transform: rotateX(0deg) rotate(90deg);
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  display: none;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: inherit;
}
.pcoded-navbar.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu .pcoded-submenu li a:before {
  opacity: 1;
}
.pcoded-navbar .pcoded-menu-caption {
  color: #e8edf7;
}
.pcoded-navbar .pcoded-inner-navbar li.active > a, .pcoded-navbar .pcoded-inner-navbar li:focus > a, .pcoded-navbar .pcoded-inner-navbar li:hover > a {
  color: #1dc4e9;
}
.pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #1dc4e9;
}
.pcoded-navbar .pcoded-inner-navbar > li.active:after, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after {
  content: "";
  background-color: #1dc4e9;
  z-index: 1027;
  position: absolute;
  left: 0;
  top: 5px;
  width: 3px;
  height: calc(100% - 5px);
}
.pcoded-navbar .pcoded-inner-navbar > li.active > a, .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.active:after, .pcoded-navbar .pcoded-inner-navbar > .pcoded-menu-caption.pcoded-trigger:after {
  display: none;
}

.pcoded-navbar.mob-open ~ .pcoded-header,
.pcoded-navbar.mob-open ~ .pcoded-main-container, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container {
  margin-left: 80px;
}
.pcoded-navbar.mob-open ~ .pcoded-header:before,
.pcoded-navbar.mob-open ~ .pcoded-main-container:before, .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
.pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* menu [ vartical ] css end */
/* menu[ horizontal ] css start */
.pcoded-navbar.theme-horizontal {
  display: block;
  height: 70px;
  width: 100%;
  z-index: 1023;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: fixed;
  top: 70px;
}
.pcoded-navbar.theme-horizontal .pcoded-badge {
  display: none;
}
.pcoded-navbar.theme-horizontal.top-nav-collapse {
  top: -70px;
}
.pcoded-navbar.theme-horizontal.default, .pcoded-navbar.theme-horizontal.default.top-nav-collapse {
  top: 70px;
}
.pcoded-navbar.theme-horizontal .header-logo {
  display: none;
}
.pcoded-navbar.theme-horizontal .sidenav-horizontal-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  white-space: nowrap;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-menu-caption {
  display: none;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li {
  display: inline-block;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a {
  margin: 0;
  padding: 8px 15px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a > .pcoded-mtext {
  position: relative;
  top: 0;
  margin-right: 5px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li > a:after {
  position: relative;
  top: 0;
  right: 0;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li:last-child > a {
  margin-right: 70px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li:hover a {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  background: transparent;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  content: "\6d";
  position: absolute;
  bottom: -34px;
  left: 0;
  font-family: "pct";
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  padding-left: calc(50% - 25px);
  color: #3f4d67;
  text-shadow: 0 3px 4px rgba(69, 90, 100, 0.05);
  width: 100%;
  height: 40px;
  -webkit-transform: scaleX(1.2);
          transform: scaleX(1.2);
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > .pcoded-submenu, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  margin-top: 30px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.active:after, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger:after {
  content: "";
  background-color: #1dc4e9;
  z-index: 1027;
  position: absolute;
  left: 19px;
  top: auto;
  bottom: 5px;
  width: 23px;
  height: 2px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.edge > .pcoded-submenu {
  left: auto;
  right: 0;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu {
  position: relative;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu {
  opacity: 0;
  visibility: hidden;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
  position: absolute;
  min-width: 250px;
  display: block;
  z-index: 1;
  top: 100%;
  list-style: outside none none;
  margin: 0;
  border-radius: 4px;
  padding: 15px;
  -webkit-box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
          box-shadow: 0 4px 24px 0 rgba(62, 57, 107, 0.18);
  background: #fff;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a {
  color: #3f4d67;
  padding: 10px 20px 10px 30px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu a:before {
  left: 5px;
  top: 19px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.active > a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li.pcoded-trigger > a, .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu li:hover > a {
  color: #04a9f5;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu {
  position: absolute;
  min-width: 250px;
  z-index: 1;
  left: calc(100% + 10px);
  top: -10px;
  margin: 0 0 0 20px;
  border-radius: 4px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu:before {
  content: "\6a";
  position: absolute;
  top: 8px;
  left: -31px;
  font-family: "pct";
  z-index: 1001;
  font-size: 50px;
  line-height: 1;
  color: #fff;
  width: 40px;
  height: 100%;
  text-shadow: -8px 0 13px rgba(62, 57, 107, 0.08);
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a {
  color: #3f4d67;
  padding: 10px 20px 10px 30px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-submenu a:before {
  left: 5px;
  top: 19px;
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu {
  left: auto;
  margin: 0 20px 0 0;
  right: calc(100% + 10px);
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu .pcoded-submenu .pcoded-hasmenu.edge .pcoded-submenu:before {
  content: '\6b';
  left: auto;
  right: -21px;
  text-shadow: 8px 0 13px rgba(62, 57, 107, 0.08);
}
.pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
}
.pcoded-navbar.theme-horizontal .navbar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header {
  position: fixed;
  top: 0;
  margin-left: 0;
  width: 100%;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
  color: #3f4d67;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*='header-'] .b-title {
  color: #fff;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 15px;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-main,
.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-thumb {
  display: none;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header .m-header .logo-dark {
  display: inline-block;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header .mobile-menu {
  display: none;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 15px;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-dark,
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-thumb {
  display: none;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .m-header .logo-main {
  display: inline-block;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu {
  color: #888;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu a {
  color: #888;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li > a {
  color: #888;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li.active, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:active, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:focus, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li.active > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:active > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:focus > a, .pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] a,
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] a:hover,
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] dropdown-toggle:hover {
  color: #fff;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .notification .noti-body li.notification:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #04a9f5;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-header[class*="header-"] .dropdown.drp-user.show:before {
  color: #04a9f5;
}
.pcoded-navbar.theme-horizontal ~ .pcoded-main-container {
  margin-top: 140px;
  margin-left: 0;
}

/* menu [ horizontal ] css end */
/* main content start */
.pcoded-content {
  position: relative;
  display: block;
  padding: 30px;
}

.pcoded-main-container {
  position: relative;
  margin: 0 auto;
  display: block;
  clear: both;
  float: unset;
  right: 0;
  margin-left: 264px;
  min-height: 100vh;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* main content end*/
/* ==========================    Rsponsive Menu  start   ====================== */
@media only screen and (max-width: 991px) {
  .pcoded-header {
    background: #3f4d67;
    color: #fff;
  }
  .pcoded-header .input-group-text,
  .pcoded-header a,
  .pcoded-header dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header .input-group-text:hover,
  .pcoded-header a:hover,
  .pcoded-header dropdown-toggle:hover {
    color: #04a9f5;
  }
  .pcoded-header #mobile-header {
    display: none;
    right: 20px;
  }
  .pcoded-header > .collapse:not(.show) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
            box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  }
  .pcoded-header .dropdown-menu,
  .pcoded-header .dropdown-menu a,
  .pcoded-header .search-close .input-group-text {
    color: #3f4d67;
  }
  .pcoded-header .m-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 70px;
  }
  .pcoded-header .m-header .b-brand {
    margin-left: 20px;
  }
  .pcoded-header .m-header .mobile-menu {
    right: 20px;
  }
  .pcoded-header .full-screen {
    display: none;
  }
  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 60px;
  }

  .pcoded-navbar {
    margin-left: -264px;
    position: absolute;
    height: 100%;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .pcoded-navbar .scroll-div.navbar-content {
    height: 100%;
  }
  .pcoded-navbar ~ .pcoded-header,
  .pcoded-navbar ~ .pcoded-main-container {
    margin-left: 0;
  }
  .pcoded-navbar ~ .pcoded-header {
    width: 100%;
  }
  .pcoded-navbar .navbar-brand {
    display: none;
  }
  .pcoded-navbar.mob-open {
    margin-left: 0;
  }
  .pcoded-navbar.mob-open ~ .pcoded-header,
  .pcoded-navbar.mob-open ~ .pcoded-main-container {
    margin-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .pcoded-header .navbar-nav > li:first-child {
    padding-left: 0;
  }
  .pcoded-header .navbar-nav > li:last-child {
    padding-right: 20px;
  }
  .pcoded-header .navbar-nav.mr-auto li {
    display: none;
  }
  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) {
    display: block;
    padding-right: 0;
  }
  .pcoded-header .navbar-nav.mr-auto li.nav-item:not(.dropdown) .search-close {
    display: none;
  }
  .pcoded-header .dropdown.show {
    position: static;
  }
  .pcoded-header .dropdown.show a:after {
    display: none;
  }
  .pcoded-header .dropdown.show:before {
    display: none;
  }
  .pcoded-header .dropdown .notification {
    width: 100%;
  }
  .pcoded-header .dropdown .dropdown-menu {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .header-chat.open,
  .header-user-list.open {
    width: 280px;
  }

  .pcoded-content {
    padding: 30px 15px;
  }

  .card {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .pcoded-navbar .header-logo {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .header-logo img {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-menu-caption > label {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a > .pcoded-mtext {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
    -webkit-transition: none;
    transition: none;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .pcoded-navbar.navbar-collapsed .pcoded-badge {
    -webkit-transition: none;
    transition: none;
  }
  .pcoded-navbar.navbar-collapsed:hover .pcoded-badge {
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
}
/* responsive horizontal menu */
@media only screen and (max-width: 992px) {
  .pcoded-navbar.theme-horizontal {
    margin-left: 0;
  }
  .pcoded-navbar.theme-horizontal ~ .pcoded-header .b-title {
    color: #fff;
  }
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger > .pcoded-submenu,
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger.edge > .pcoded-submenu {
    position: relative;
    left: 0;
    min-width: 100%;
    margin: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger > .pcoded-submenu:before,
  .pcoded-navbar.theme-horizontal .pcoded-inner-navbar .pcoded-hasmenu.pcoded-trigger > .pcoded-submenu .pcoded-trigger.edge > .pcoded-submenu:before {
    display: none;
  }
}
/* ==========================    Rsponsive Menu  end   ====================== */
/* ******************************************************************************* */
/* * Horizontal */
.theme-horizontal .sidenav-horizontal-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
  width: 0;
}
.sidenav:not(.sidenav-no-animation) .theme-horizontal .sidenav-horizontal-wrapper .sidenav-inner {
  -webkit-transition: margin 0.2s;
  transition: margin 0.2s;
}
.theme-horizontal .sidenav-horizontal-next,
.theme-horizontal .sidenav-horizontal-prev {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 2.25rem;
  background-color: #3f4d67;
  z-index: 9;
}
.theme-horizontal .sidenav-horizontal-next::after,
.theme-horizontal .sidenav-horizontal-prev::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border: 2px solid;
  border-top: 0;
}
.theme-horizontal .sidenav-horizontal-next.disabled,
.theme-horizontal .sidenav-horizontal-prev.disabled {
  cursor: default !important;
  opacity: 0;
}
.theme-horizontal .sidenav-horizontal-prev::after {
  border-right: 0;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
          transform: translate(-50%, -50%) rotate(45deg);
}
.theme-horizontal .sidenav-horizontal-next::after {
  border-left: 0;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

@-webkit-keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes sidenavDropdownShow {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
.menu-styler {
  z-index: 1029;
  position: relative;
  color: #888;
}
.menu-styler h5,
.menu-styler h6 {
  color: #111;
  font-weight: 400;
}
.menu-styler hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.menu-styler .style-toggler > a {
  position: fixed;
  right: 0;
  top: 30vh;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.menu-styler .style-toggler > a:before {
  content: "\62";
  top: 0;
  font-size: 120px;
  position: absolute;
  right: -45px;
  font-family: "pct";
  z-index: 1001;
  line-height: 0;
  color: #04a9f5;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-shadow: 1px 0 8px rgba(4, 169, 245, 0.9), 6px 0 8px rgba(0, 0, 0, 0.1);
  letter-spacing: -44px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.menu-styler .style-toggler > a:after {
  content: "\e8c3";
  top: -7px;
  font-size: 18px;
  position: absolute;
  right: 4px;
  font-family: "feather";
  z-index: 1029;
  line-height: 1;
  color: #fff;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-animation: anim-rotate 1.5s linear infinite;
          animation: anim-rotate 1.5s linear infinite;
}
@media all and (-ms-high-contrast: none) {
  .menu-styler .style-toggler > a:after {
    line-height: 0.5;
  }
}
.menu-styler.open .style-toggler > a {
  right: 400px;
}
.menu-styler.open .style-toggler > a:after {
  content: "\e847";
  top: -7px;
  font-size: 18px;
  -webkit-animation: anim-rotate 0s linear infinite;
          animation: anim-rotate 0s linear infinite;
}
.menu-styler.open .style-block {
  right: 0;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}
.menu-styler .style-block {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background: #fff;
  z-index: 1030;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 25px 20px 20px;
}
.menu-styler .mst-scroll {
  height: calc(100vh - 175px);
  background-color: rgba(4, 169, 245, 0.03);
  padding: 0 15px;
}
.menu-styler label {
  margin-bottom: 3px;
}
.menu-styler .switch input[type=checkbox] + .cr {
  top: 8px;
}
.menu-styler .radio input[type=radio] + .cr {
  margin-right: 10px;
}
.menu-styler .radio input[type=radio] + .cr:after, .menu-styler .radio input[type=radio] + .cr:before {
  margin-right: 3px;
}
.menu-styler h6 {
  position: relative;
  margin: 10px 0 15px;
}
.menu-styler h6:after {
  content: "";
  background-color: #04a9f5;
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 40px;
  height: 2px;
}
.menu-styler .nav-pills {
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.menu-styler .nav-pills li {
  width: calc(100% / 3);
}
.menu-styler .tab-content {
  padding: 15px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: rgba(4, 169, 245, 0.03);
  border-top: 2px solid #04a9f5;
}
@media only screen and (max-width: 992px) {
  .menu-styler {
    display: none;
  }
}

.lay-customizer .theme-color,
.theme-color {
  display: block;
  position: relative;
  padding: 5px 5px 0;
  margin-bottom: 10px;
}
.lay-customizer .theme-color > a,
.theme-color > a {
  position: relative;
  width: 45px;
  height: 30px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 8px;
  background: #f4f7fa;
  overflow: hidden;
  -webkit-box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
          box-shadow: 0.4px 0.9px 3px 0 rgba(0, 0, 0, 0.33);
}
.lay-customizer .theme-color > a span,
.theme-color > a span {
  width: 100%;
  position: absolute;
}
.lay-customizer .theme-color > a span:after, .lay-customizer .theme-color > a span:before,
.theme-color > a span:after,
.theme-color > a span:before {
  content: "";
  height: 100%;
  position: absolute;
}
.lay-customizer .theme-color > a span:before,
.theme-color > a span:before {
  width: 30%;
  left: 0;
  background: #3f4d67;
}
.lay-customizer .theme-color > a span:after,
.theme-color > a span:after {
  width: 70%;
  right: 0;
  background: #f4f7fa;
}
.lay-customizer .theme-color > a > span:nth-child(1),
.theme-color > a > span:nth-child(1) {
  height: 30%;
  top: 0;
}
.lay-customizer .theme-color > a > span:nth-child(2),
.theme-color > a > span:nth-child(2) {
  height: 75%;
  bottom: 0;
}
.lay-customizer .theme-color > a.active,
.theme-color > a.active {
  -webkit-box-shadow: 0 0 0 3px #04a9f5;
          box-shadow: 0 0 0 3px #04a9f5;
}
.lay-customizer .theme-color > a.active:before,
.theme-color > a.active:before {
  content: "\e8dc";
  top: -1px;
  font-size: 20px;
  position: absolute;
  left: 12px;
  font-family: "feather";
  z-index: 1001;
  color: #04a9f5;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-shadow: 0 1px 3px rgba(4, 169, 245, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1);
}
.lay-customizer .theme-color > a.active:after,
.theme-color > a.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}
.lay-customizer .theme-color > a[data-value="menu-light"] span:before,
.theme-color > a[data-value="menu-light"] span:before {
  background: #fff;
}
.lay-customizer .theme-color > a[data-value="dark"] span:after,
.theme-color > a[data-value="dark"] span:after {
  background: #101011;
}
.lay-customizer .theme-color > a[data-value="reset"],
.theme-color > a[data-value="reset"] {
  background: #f44236;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: -10px;
  width: 135px;
}
.lay-customizer .theme-color.small > a,
.theme-color.small > a {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.lay-customizer .theme-color.small > a:before,
.theme-color.small > a:before {
  font-size: 15px;
  left: 5px;
  top: 1px;
}
.lay-customizer .theme-color.small > a > span,
.theme-color.small > a > span {
  display: none;
}
.lay-customizer .theme-color.header-color > a[data-value="header-blue"] > span:nth-child(1):after,
.theme-color.header-color > a[data-value="header-blue"] > span:nth-child(1):after {
  background: #04a9f5;
}
.lay-customizer .theme-color.header-color.small > a[data-value="header-blue"],
.theme-color.header-color.small > a[data-value="header-blue"] {
  background: #04a9f5;
}
.lay-customizer .theme-color.header-color > a[data-value="header-red"] > span:nth-child(1):after,
.theme-color.header-color > a[data-value="header-red"] > span:nth-child(1):after {
  background: #ff5252;
}
.lay-customizer .theme-color.header-color.small > a[data-value="header-red"],
.theme-color.header-color.small > a[data-value="header-red"] {
  background: #ff5252;
}
.lay-customizer .theme-color.header-color > a[data-value="header-purple"] > span:nth-child(1):after,
.theme-color.header-color > a[data-value="header-purple"] > span:nth-child(1):after {
  background: #9575CD;
}
.lay-customizer .theme-color.header-color.small > a[data-value="header-purple"],
.theme-color.header-color.small > a[data-value="header-purple"] {
  background: #9575CD;
}
.lay-customizer .theme-color.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after,
.theme-color.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after {
  background: #23b7e5;
}
.lay-customizer .theme-color.header-color.small > a[data-value="header-lightblue"],
.theme-color.header-color.small > a[data-value="header-lightblue"] {
  background: #23b7e5;
}
.lay-customizer .theme-color.header-color > a[data-value="header-dark"] > span:nth-child(1):after,
.theme-color.header-color > a[data-value="header-dark"] > span:nth-child(1):after {
  background: #323437;
}
.lay-customizer .theme-color.header-color.small > a[data-value="header-dark"],
.theme-color.header-color.small > a[data-value="header-dark"] {
  background: #323437;
}
.lay-customizer .theme-color.navbar-color > a[data-value="navbar-blue"] > span:before,
.theme-color.navbar-color > a[data-value="navbar-blue"] > span:before {
  background: #04a9f5;
}
.lay-customizer .theme-color.navbar-color > a[data-value="navbar-red"] > span:before,
.theme-color.navbar-color > a[data-value="navbar-red"] > span:before {
  background: #ff5252;
}
.lay-customizer .theme-color.navbar-color > a[data-value="navbar-purple"] > span:before,
.theme-color.navbar-color > a[data-value="navbar-purple"] > span:before {
  background: #9575CD;
}
.lay-customizer .theme-color.navbar-color > a[data-value="navbar-lightblue"] > span:before,
.theme-color.navbar-color > a[data-value="navbar-lightblue"] > span:before {
  background: #23b7e5;
}
.lay-customizer .theme-color.navbar-color > a[data-value="navbar-dark"] > span:before,
.theme-color.navbar-color > a[data-value="navbar-dark"] > span:before {
  background: #323437;
}
.lay-customizer .theme-color.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before,
.theme-color.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before {
  background: #04a9f5;
}
.lay-customizer .theme-color.brand-color.small > a[data-value="brand-blue"],
.theme-color.brand-color.small > a[data-value="brand-blue"] {
  background: #04a9f5;
}
.lay-customizer .theme-color.brand-color > a[data-value="brand-red"] > span:nth-child(1):before,
.theme-color.brand-color > a[data-value="brand-red"] > span:nth-child(1):before {
  background: #ff5252;
}
.lay-customizer .theme-color.brand-color.small > a[data-value="brand-red"],
.theme-color.brand-color.small > a[data-value="brand-red"] {
  background: #ff5252;
}
.lay-customizer .theme-color.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before,
.theme-color.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before {
  background: #9575CD;
}
.lay-customizer .theme-color.brand-color.small > a[data-value="brand-purple"],
.theme-color.brand-color.small > a[data-value="brand-purple"] {
  background: #9575CD;
}
.lay-customizer .theme-color.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before,
.theme-color.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before {
  background: #23b7e5;
}
.lay-customizer .theme-color.brand-color.small > a[data-value="brand-lightblue"],
.theme-color.brand-color.small > a[data-value="brand-lightblue"] {
  background: #23b7e5;
}
.lay-customizer .theme-color.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before,
.theme-color.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before {
  background: #323437;
}
.lay-customizer .theme-color.brand-color.small > a[data-value="brand-dark"],
.theme-color.brand-color.small > a[data-value="brand-dark"] {
  background: #323437;
}
.lay-customizer .theme-color.active-color > a[data-value="active-default"],
.theme-color.active-color > a[data-value="active-default"] {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.lay-customizer .theme-color.active-color > a[data-value="active-blue"],
.theme-color.active-color > a[data-value="active-blue"] {
  background: #04a9f5;
}
.lay-customizer .theme-color.active-color > a[data-value="active-red"],
.theme-color.active-color > a[data-value="active-red"] {
  background: #ff5252;
}
.lay-customizer .theme-color.active-color > a[data-value="active-purple"],
.theme-color.active-color > a[data-value="active-purple"] {
  background: #9575CD;
}
.lay-customizer .theme-color.active-color > a[data-value="active-lightblue"],
.theme-color.active-color > a[data-value="active-lightblue"] {
  background: #23b7e5;
}
.lay-customizer .theme-color.active-color > a[data-value="active-dark"],
.theme-color.active-color > a[data-value="active-dark"] {
  background: #323437;
}
.lay-customizer .theme-color.title-color > a[data-value="title-default"],
.theme-color.title-color > a[data-value="title-default"] {
  background: #fff;
}
.lay-customizer .theme-color.title-color > a[data-value="title-blue"],
.theme-color.title-color > a[data-value="title-blue"] {
  background: #04a9f5;
}
.lay-customizer .theme-color.title-color > a[data-value="title-red"],
.theme-color.title-color > a[data-value="title-red"] {
  background: #ff5252;
}
.lay-customizer .theme-color.title-color > a[data-value="title-purple"],
.theme-color.title-color > a[data-value="title-purple"] {
  background: #9575CD;
}
.lay-customizer .theme-color.title-color > a[data-value="title-lightblue"],
.theme-color.title-color > a[data-value="title-lightblue"] {
  background: #23b7e5;
}
.lay-customizer .theme-color.title-color > a[data-value="title-dark"],
.theme-color.title-color > a[data-value="title-dark"] {
  background: #323437;
}
.lay-customizer .theme-color.navbar-images > a,
.theme-color.navbar-images > a {
  height: 90px;
  background-size: cover;
  background-position: center center;
}
.lay-customizer .theme-color.navbar-images > a span,
.theme-color.navbar-images > a span {
  display: none;
}
.lay-customizer .theme-color.navbar-images > a.active:before,
.theme-color.navbar-images > a.active:before {
  top: 35px;
  content: "\e87c";
}
/* .lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-1"],
.theme-color.navbar-images > a[data-value="navbar-image-1"] {
  background-image: url("../images/nav-bg/navbar-img-1.jpg");
}
.lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-2"],
.theme-color.navbar-images > a[data-value="navbar-image-2"] {
  background-image: url("../images/nav-bg/navbar-img-2.jpg");
}
.lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-3"],
.theme-color.navbar-images > a[data-value="navbar-image-3"] {
  background-image: url("../images/nav-bg/navbar-img-3.jpg");
}
.lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-4"],
.theme-color.navbar-images > a[data-value="navbar-image-4"] {
  background-image: url("../images/nav-bg/navbar-img-4.jpg");
}
.lay-customizer .theme-color.navbar-images > a[data-value="navbar-image-5"],
.theme-color.navbar-images > a[data-value="navbar-image-5"] {
  background-image: url("../images/nav-bg/navbar-img-5.jpg");
} */
.lay-customizer .theme-color.bg-images > a,
.theme-color.bg-images > a {
  width: 95px;
  height: 80px;
  border-radius: 2px;
  background-size: cover;
  background-position: center center;
}
.lay-customizer .theme-color.bg-images > a span,
.theme-color.bg-images > a span {
  display: none;
}
.lay-customizer .theme-color.bg-images > a.active:before,
.theme-color.bg-images > a.active:before {
  top: 35px;
  content: "\e87c";
}
.lay-customizer .theme-color.prelayout-color > a,
.theme-color.prelayout-color > a {
  margin-bottom: 5px;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(1):before {
  background: #3f4d67;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l2"] > span:nth-child(2):before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(1):before {
  background: #3f4d67;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l2-2"] > span:nth-child(2):before {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(1):before {
  background: #3f4d67;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l3"] > span:nth-child(2):before {
  background: #04a9f5;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(1):before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l4"] > span:nth-child(2):before {
  background: #212224;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(1):before {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l4-2"] > span:nth-child(2):before {
  background: #212224;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1),
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1) {
  height: 40%;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):after,
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(1):before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #04a9f5), color-stop(0%, #fff));
  background: linear-gradient(#04a9f5 50%, #fff 0%);
  -webkit-box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
          box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
  z-index: 1;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2),
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2) {
  height: 60%;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):after,
.theme-color.prelayout-color > a[data-value="l5-h"] > span:nth-child(2):before {
  background: #f4f7fa;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):after, .lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):after,
.theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(1):before {
  background: #23b7e5;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l-lite"] > span:nth-child(2):before {
  background: #fff;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1),
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1) {
  height: 50%;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):after,
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):after {
  background: #23b7e5;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(1):before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #23b7e5), color-stop(0%, #fff));
  background: linear-gradient(#23b7e5 50%, #fff 0%);
  z-index: 1;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2),
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2) {
  height: 50%;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):after,
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):after {
  background: #f4f7fa;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l6"] > span:nth-child(2):before {
  background: #fff;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):after,
.theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):after {
  background: #23b7e5;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):before,
.theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(1):before {
  background: #23b7e5;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):after,
.theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):after {
  background: #f4f7fa;
}
.lay-customizer .theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):before,
.theme-color.prelayout-color > a[data-value="l8"] > span:nth-child(2):before {
  background: linear-gradient(#fff 0px, #fff 5px, rgba(35, 183, 229, 0.5) 5px, rgba(35, 183, 229, 0.5) 5px, rgba(35, 183, 229, 0.5) 8px, #fff 8px, #fff);
}

/* ===================================================================================
 ==========================    Diffrent Layout Styles Start     ====================
 ===================================================================================
 ====== [ light menu style start ] ======== */
.pcoded-navbar.menu-light {
  background-color: #fff;
  color: #3f4d67;
  -webkit-box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
          box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
}
.pcoded-navbar.menu-light .header-logo {
  background-color: #3f4d67;
}
.pcoded-navbar.menu-light .header-logo .logo-dark {
  display: none;
}
.pcoded-navbar.menu-light .header-logo .logo-main {
  display: block;
}
.pcoded-navbar.menu-light .sidenav-horizontal-next,
.pcoded-navbar.menu-light .sidenav-horizontal-prev {
  background: #fff;
}
.pcoded-navbar.menu-light .mobile-menu span {
  background-color: #a9b7d0;
}
.pcoded-navbar.menu-light .mobile-menu span:after, .pcoded-navbar.menu-light .mobile-menu span:before {
  background-color: #a9b7d0;
}
.pcoded-navbar.menu-light .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.menu-light .pcoded-submenu {
  background: #fbfbfb;
}
.pcoded-navbar.menu-light a {
  color: #3f4d67;
}
.pcoded-navbar.menu-light.navbar-collapsed .pcoded-menu-caption:after {
  background: rgba(0, 0, 0, 0.1);
}
.pcoded-navbar.menu-light.navbar-collapsed:hover .pcoded-menu-caption:after {
  background: transparent;
}
.pcoded-navbar.menu-light .pcoded-menu-caption {
  color: #3f4d67;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.active a, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger a, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li:hover a {
  color: #3f4d67;
  background: transparent;
}
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #fff;
}

/* ====== [ light menu style end ] ======== */
/* ====== [ Menu icon start ] ======== */
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(1) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(2) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(3) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(4) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(5) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(6) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(7) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(8) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(9) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(10) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(11) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(12) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(13) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(14) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(15) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(16) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(17) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(18) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(19) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(20) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(21) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(22) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(23) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(24) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(25) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(26) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(27) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(28) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(29) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(30) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(31) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(32) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(33) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(34) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(35) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(36) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(37) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(38) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(39) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(40) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(41) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(42) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(43) > a > .pcoded-micon {
  color: #04a9f5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(44) > a > .pcoded-micon {
  color: #ff5252;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(45) > a > .pcoded-micon {
  color: #01a9ac;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(46) > a > .pcoded-micon {
  color: #9575CD;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(47) > a > .pcoded-micon {
  color: #23b7e5;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li:nth-child(48) > a > .pcoded-micon {
  color: #f4c22b;
}
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li.active > a > .pcoded-micon,
.pcoded-navbar.icon-colored .pcoded-inner-navbar > li.pcoded-trigger > a > .pcoded-micon {
  color: #fff;
}
.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar > li.active > a > .pcoded-micon,
.pcoded-navbar.icon-colored.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a > .pcoded-micon {
  color: #3f4d67;
}

/* ====== [ Menu icon end ] ======== */
/* ====== [ Header color start ] ======== */
.theme-horizontal ~ .pcoded-header {
  background: #fff;
  -webkit-box-shadow: 0 1px 20px 0 rgba(63, 77, 103, 0.15);
          box-shadow: 0 1px 20px 0 rgba(63, 77, 103, 0.15);
}
@media only screen and (max-width: 991px) {
  .theme-horizontal ~ .pcoded-header {
    background: #3f4d67;
  }
  .theme-horizontal ~ .pcoded-header .horizontal-dasktop {
    display: none;
  }
  .theme-horizontal ~ .pcoded-header .horizontal-mobile {
    display: inline-block;
  }
}

@media only screen and (max-width: 992px) {
  .theme-horizontal-dis ~ .pcoded-header .horizontal-dasktop {
    display: none;
  }
  .theme-horizontal-dis ~ .pcoded-header .horizontal-mobile {
    display: inline-block;
  }
}

.pcoded-header[class*="header-"] {
  -webkit-box-shadow: 0 3px 10px 0 rgba(65, 72, 78, 0.02);
          box-shadow: 0 3px 10px 0 rgba(65, 72, 78, 0.02);
}
.pcoded-header.header-blue {
  background: #04a9f5;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-blue .profile-notification li > a {
  color: #888;
}
.pcoded-header.header-blue .profile-notification li.active, .pcoded-header.header-blue .profile-notification li:active, .pcoded-header.header-blue .profile-notification li:focus, .pcoded-header.header-blue .profile-notification li:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header.header-blue .profile-notification li.active > a, .pcoded-header.header-blue .profile-notification li:active > a, .pcoded-header.header-blue .profile-notification li:focus > a, .pcoded-header.header-blue .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-blue .dropdown-menu {
  color: #888;
}
.pcoded-header.header-blue .dropdown-menu a {
  color: #888;
}
.pcoded-header.header-blue .dropdown-menu > li > a {
  color: #888;
}
.pcoded-header.header-blue .dropdown-menu > li.active, .pcoded-header.header-blue .dropdown-menu > li:active, .pcoded-header.header-blue .dropdown-menu > li:focus, .pcoded-header.header-blue .dropdown-menu > li:hover {
  background: rgba(4, 169, 245, 0.1);
  color: #888;
}
.pcoded-header.header-blue .dropdown-menu > li.active > a, .pcoded-header.header-blue .dropdown-menu > li:active > a, .pcoded-header.header-blue .dropdown-menu > li:focus > a, .pcoded-header.header-blue .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-blue .input-group .input-group-text,
.pcoded-header.header-blue a,
.pcoded-header.header-blue dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-blue .input-group .input-group-text:hover,
.pcoded-header.header-blue a:hover,
.pcoded-header.header-blue dropdown-toggle:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-blue .input-group .input-group-text,
  .pcoded-header.header-blue a,
  .pcoded-header.header-blue dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header.header-blue .input-group .input-group-text:hover,
  .pcoded-header.header-blue a:hover,
  .pcoded-header.header-blue dropdown-toggle:hover {
    color: #2c3547;
  }
}
.pcoded-header.header-blue .main-search .search-close > .input-group-text {
  color: #04a9f5;
}
.pcoded-header.header-blue .main-search.open .input-group .search-btn {
  background: #04a9f5;
  border-color: #04a9f5;
}
.pcoded-header.header-blue .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-blue .dropdown .notification .noti-body li.notification:hover {
  background: rgba(4, 169, 245, 0.1);
}
.pcoded-header.header-blue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #04a9f5;
}
.pcoded-header.header-blue .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-blue .dropdown.drp-user.show:before {
  color: #04a9f5;
}
.pcoded-header.header-blue .b-bg {
  background: #fff;
}
.pcoded-header.header-blue .b-bg i {
  color: #04a9f5;
  background-image: #04a9f5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}
.pcoded-header.header-red {
  background: #ff5252;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-red .profile-notification li > a {
  color: #888;
}
.pcoded-header.header-red .profile-notification li.active, .pcoded-header.header-red .profile-notification li:active, .pcoded-header.header-red .profile-notification li:focus, .pcoded-header.header-red .profile-notification li:hover {
  background: rgba(255, 82, 82, 0.1);
}
.pcoded-header.header-red .profile-notification li.active > a, .pcoded-header.header-red .profile-notification li:active > a, .pcoded-header.header-red .profile-notification li:focus > a, .pcoded-header.header-red .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-red .dropdown-menu {
  color: #888;
}
.pcoded-header.header-red .dropdown-menu a {
  color: #888;
}
.pcoded-header.header-red .dropdown-menu > li > a {
  color: #888;
}
.pcoded-header.header-red .dropdown-menu > li.active, .pcoded-header.header-red .dropdown-menu > li:active, .pcoded-header.header-red .dropdown-menu > li:focus, .pcoded-header.header-red .dropdown-menu > li:hover {
  background: rgba(255, 82, 82, 0.1);
  color: #888;
}
.pcoded-header.header-red .dropdown-menu > li.active > a, .pcoded-header.header-red .dropdown-menu > li:active > a, .pcoded-header.header-red .dropdown-menu > li:focus > a, .pcoded-header.header-red .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-red .input-group .input-group-text,
.pcoded-header.header-red a,
.pcoded-header.header-red dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-red .input-group .input-group-text:hover,
.pcoded-header.header-red a:hover,
.pcoded-header.header-red dropdown-toggle:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-red .input-group .input-group-text,
  .pcoded-header.header-red a,
  .pcoded-header.header-red dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header.header-red .input-group .input-group-text:hover,
  .pcoded-header.header-red a:hover,
  .pcoded-header.header-red dropdown-toggle:hover {
    color: #2c3547;
  }
}
.pcoded-header.header-red .main-search .search-close > .input-group-text {
  color: #ff5252;
}
.pcoded-header.header-red .main-search.open .input-group .search-btn {
  background: #ff5252;
  border-color: #ff5252;
}
.pcoded-header.header-red .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-red .dropdown .notification .noti-body li.notification:hover {
  background: rgba(255, 82, 82, 0.1);
}
.pcoded-header.header-red .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #ff5252;
}
.pcoded-header.header-red .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-red .dropdown.drp-user.show:before {
  color: #ff5252;
}
.pcoded-header.header-red .b-bg {
  background: #fff;
}
.pcoded-header.header-red .b-bg i {
  color: #ff5252;
  background-image: #ff5252;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}
.pcoded-header.header-purple {
  background: #9575CD;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-purple .profile-notification li > a {
  color: #888;
}
.pcoded-header.header-purple .profile-notification li.active, .pcoded-header.header-purple .profile-notification li:active, .pcoded-header.header-purple .profile-notification li:focus, .pcoded-header.header-purple .profile-notification li:hover {
  background: rgba(149, 117, 205, 0.1);
}
.pcoded-header.header-purple .profile-notification li.active > a, .pcoded-header.header-purple .profile-notification li:active > a, .pcoded-header.header-purple .profile-notification li:focus > a, .pcoded-header.header-purple .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-purple .dropdown-menu {
  color: #888;
}
.pcoded-header.header-purple .dropdown-menu a {
  color: #888;
}
.pcoded-header.header-purple .dropdown-menu > li > a {
  color: #888;
}
.pcoded-header.header-purple .dropdown-menu > li.active, .pcoded-header.header-purple .dropdown-menu > li:active, .pcoded-header.header-purple .dropdown-menu > li:focus, .pcoded-header.header-purple .dropdown-menu > li:hover {
  background: rgba(149, 117, 205, 0.1);
  color: #888;
}
.pcoded-header.header-purple .dropdown-menu > li.active > a, .pcoded-header.header-purple .dropdown-menu > li:active > a, .pcoded-header.header-purple .dropdown-menu > li:focus > a, .pcoded-header.header-purple .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-purple .input-group .input-group-text,
.pcoded-header.header-purple a,
.pcoded-header.header-purple dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-purple .input-group .input-group-text:hover,
.pcoded-header.header-purple a:hover,
.pcoded-header.header-purple dropdown-toggle:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-purple .input-group .input-group-text,
  .pcoded-header.header-purple a,
  .pcoded-header.header-purple dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header.header-purple .input-group .input-group-text:hover,
  .pcoded-header.header-purple a:hover,
  .pcoded-header.header-purple dropdown-toggle:hover {
    color: #2c3547;
  }
}
.pcoded-header.header-purple .main-search .search-close > .input-group-text {
  color: #9575CD;
}
.pcoded-header.header-purple .main-search.open .input-group .search-btn {
  background: #9575CD;
  border-color: #9575CD;
}
.pcoded-header.header-purple .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-purple .dropdown .notification .noti-body li.notification:hover {
  background: rgba(149, 117, 205, 0.1);
}
.pcoded-header.header-purple .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #9575CD;
}
.pcoded-header.header-purple .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-purple .dropdown.drp-user.show:before {
  color: #9575CD;
}
.pcoded-header.header-purple .b-bg {
  background: #fff;
}
.pcoded-header.header-purple .b-bg i {
  color: #9575CD;
  background-image: #9575CD;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}
.pcoded-header.header-lightblue {
  background: #23b7e5;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-lightblue .profile-notification li > a {
  color: #888;
}
.pcoded-header.header-lightblue .profile-notification li.active, .pcoded-header.header-lightblue .profile-notification li:active, .pcoded-header.header-lightblue .profile-notification li:focus, .pcoded-header.header-lightblue .profile-notification li:hover {
  background: rgba(35, 183, 229, 0.1);
}
.pcoded-header.header-lightblue .profile-notification li.active > a, .pcoded-header.header-lightblue .profile-notification li:active > a, .pcoded-header.header-lightblue .profile-notification li:focus > a, .pcoded-header.header-lightblue .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-lightblue .dropdown-menu {
  color: #888;
}
.pcoded-header.header-lightblue .dropdown-menu a {
  color: #888;
}
.pcoded-header.header-lightblue .dropdown-menu > li > a {
  color: #888;
}
.pcoded-header.header-lightblue .dropdown-menu > li.active, .pcoded-header.header-lightblue .dropdown-menu > li:active, .pcoded-header.header-lightblue .dropdown-menu > li:focus, .pcoded-header.header-lightblue .dropdown-menu > li:hover {
  background: rgba(35, 183, 229, 0.1);
  color: #888;
}
.pcoded-header.header-lightblue .dropdown-menu > li.active > a, .pcoded-header.header-lightblue .dropdown-menu > li:active > a, .pcoded-header.header-lightblue .dropdown-menu > li:focus > a, .pcoded-header.header-lightblue .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-lightblue .input-group .input-group-text,
.pcoded-header.header-lightblue a,
.pcoded-header.header-lightblue dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-lightblue .input-group .input-group-text:hover,
.pcoded-header.header-lightblue a:hover,
.pcoded-header.header-lightblue dropdown-toggle:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-lightblue .input-group .input-group-text,
  .pcoded-header.header-lightblue a,
  .pcoded-header.header-lightblue dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header.header-lightblue .input-group .input-group-text:hover,
  .pcoded-header.header-lightblue a:hover,
  .pcoded-header.header-lightblue dropdown-toggle:hover {
    color: #2c3547;
  }
}
.pcoded-header.header-lightblue .main-search .search-close > .input-group-text {
  color: #23b7e5;
}
.pcoded-header.header-lightblue .main-search.open .input-group .search-btn {
  background: #23b7e5;
  border-color: #23b7e5;
}
.pcoded-header.header-lightblue .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-lightblue .dropdown .notification .noti-body li.notification:hover {
  background: rgba(35, 183, 229, 0.1);
}
.pcoded-header.header-lightblue .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #23b7e5;
}
.pcoded-header.header-lightblue .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-lightblue .dropdown.drp-user.show:before {
  color: #23b7e5;
}
.pcoded-header.header-lightblue .b-bg {
  background: #fff;
}
.pcoded-header.header-lightblue .b-bg i {
  color: #23b7e5;
  background-image: #23b7e5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}
.pcoded-header.header-dark {
  background: #323437;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-dark .profile-notification li > a {
  color: #888;
}
.pcoded-header.header-dark .profile-notification li.active, .pcoded-header.header-dark .profile-notification li:active, .pcoded-header.header-dark .profile-notification li:focus, .pcoded-header.header-dark .profile-notification li:hover {
  background: rgba(50, 52, 55, 0.1);
}
.pcoded-header.header-dark .profile-notification li.active > a, .pcoded-header.header-dark .profile-notification li:active > a, .pcoded-header.header-dark .profile-notification li:focus > a, .pcoded-header.header-dark .profile-notification li:hover > a {
  background: transparent;
}
.pcoded-header.header-dark .dropdown-menu {
  color: #888;
}
.pcoded-header.header-dark .dropdown-menu a {
  color: #888;
}
.pcoded-header.header-dark .dropdown-menu > li > a {
  color: #888;
}
.pcoded-header.header-dark .dropdown-menu > li.active, .pcoded-header.header-dark .dropdown-menu > li:active, .pcoded-header.header-dark .dropdown-menu > li:focus, .pcoded-header.header-dark .dropdown-menu > li:hover {
  background: rgba(50, 52, 55, 0.1);
  color: #888;
}
.pcoded-header.header-dark .dropdown-menu > li.active > a, .pcoded-header.header-dark .dropdown-menu > li:active > a, .pcoded-header.header-dark .dropdown-menu > li:focus > a, .pcoded-header.header-dark .dropdown-menu > li:hover > a {
  background: transparent;
}
.pcoded-header.header-dark .input-group .input-group-text,
.pcoded-header.header-dark a,
.pcoded-header.header-dark dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-header.header-dark .input-group .input-group-text:hover,
.pcoded-header.header-dark a:hover,
.pcoded-header.header-dark dropdown-toggle:hover {
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.header-dark .input-group .input-group-text,
  .pcoded-header.header-dark a,
  .pcoded-header.header-dark dropdown-toggle {
    color: #3f4d67;
  }
  .pcoded-header.header-dark .input-group .input-group-text:hover,
  .pcoded-header.header-dark a:hover,
  .pcoded-header.header-dark dropdown-toggle:hover {
    color: #2c3547;
  }
}
.pcoded-header.header-dark .main-search .search-close > .input-group-text {
  color: #323437;
}
.pcoded-header.header-dark .main-search.open .input-group .search-btn {
  background: #323437;
  border-color: #323437;
}
.pcoded-header.header-dark .main-search.open .input-group .search-btn .input-group-text {
  color: #fff;
}
.pcoded-header.header-dark .dropdown .notification .noti-body li.notification:hover {
  background: rgba(50, 52, 55, 0.1);
}
.pcoded-header.header-dark .dropdown .profile-notification .pro-head {
  color: #fff;
  background: #323437;
}
.pcoded-header.header-dark .dropdown .profile-notification .pro-head .dud-logout {
  color: #fff;
}
.pcoded-header.header-dark .dropdown.drp-user.show:before {
  color: #323437;
}
.pcoded-header.header-dark .b-bg {
  background: #fff;
}
.pcoded-header.header-dark .b-bg i {
  color: #323437;
  background-image: #323437;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
}

/* ====== [ Header color end ] ======== */
/* ====== [ Brand color start ] ======== */
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:after,
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu span:before {
  background: #fff;
}
.pcoded-navbar[class*="brand-"]:not(.brand-default) .mobile-menu.on span {
  background: transparent;
}
.pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg {
  background: #fff;
}
.pcoded-navbar[class*="brand-"]:not(.brand-default) .b-bg i {
  color: #1de9b6;
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pcoded-navbar.brand-blue .header-logo, .pcoded-navbar[class*="navbar-"].brand-blue .header-logo {
  background: #04a9f5;
}
.pcoded-navbar.brand-blue .header-logo .b-bg, .pcoded-navbar[class*="navbar-"].brand-blue .header-logo .b-bg {
  background: #fff;
}
.pcoded-navbar.brand-blue .header-logo .b-bg i, .pcoded-navbar[class*="navbar-"].brand-blue .header-logo .b-bg i {
  color: #04a9f5;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.brand-red .header-logo, .pcoded-navbar[class*="navbar-"].brand-red .header-logo {
  background: #ff5252;
}
.pcoded-navbar.brand-red .header-logo .b-bg, .pcoded-navbar[class*="navbar-"].brand-red .header-logo .b-bg {
  background: #fff;
}
.pcoded-navbar.brand-red .header-logo .b-bg i, .pcoded-navbar[class*="navbar-"].brand-red .header-logo .b-bg i {
  color: #ff5252;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.brand-purple .header-logo, .pcoded-navbar[class*="navbar-"].brand-purple .header-logo {
  background: #9575CD;
}
.pcoded-navbar.brand-purple .header-logo .b-bg, .pcoded-navbar[class*="navbar-"].brand-purple .header-logo .b-bg {
  background: #fff;
}
.pcoded-navbar.brand-purple .header-logo .b-bg i, .pcoded-navbar[class*="navbar-"].brand-purple .header-logo .b-bg i {
  color: #9575CD;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.brand-lightblue .header-logo, .pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo {
  background: #23b7e5;
}
.pcoded-navbar.brand-lightblue .header-logo .b-bg, .pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo .b-bg {
  background: #fff;
}
.pcoded-navbar.brand-lightblue .header-logo .b-bg i, .pcoded-navbar[class*="navbar-"].brand-lightblue .header-logo .b-bg i {
  color: #23b7e5;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.brand-dark .header-logo, .pcoded-navbar[class*="navbar-"].brand-dark .header-logo {
  background: #323437;
}
.pcoded-navbar.brand-dark .header-logo .b-bg, .pcoded-navbar[class*="navbar-"].brand-dark .header-logo .b-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.pcoded-navbar.brand-dark .header-logo .b-bg i, .pcoded-navbar[class*="navbar-"].brand-dark .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.brand-default .header-logo, .pcoded-navbar[class*="navbar-"].brand-default .header-logo {
  background: #3f4d67;
}

/* ====== [ Brand color end ] ======== */
/* ====== [ Menu color start ] ======== */
.pcoded-navbar.navbar-blue {
  background: #04a9f5;
}
.pcoded-navbar.navbar-blue .mobile-menu span {
  background-color: #fff;
}
.pcoded-navbar.navbar-blue .mobile-menu span:after, .pcoded-navbar.navbar-blue .mobile-menu span:before {
  background-color: #fff;
}
.pcoded-navbar.navbar-blue .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.navbar-blue .header-logo .b-bg {
  background: #04a9f5;
}
.pcoded-navbar.navbar-blue .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.navbar-blue .navbar-brand,
.pcoded-navbar.navbar-blue .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar.navbar-blue .pcoded-menu-caption {
  color: #fff;
}
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li a {
  color: #fff;
}
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-blue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.navbar-blue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev {
  background: #04a9f5;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-blue.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}
.pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #04a9f5;
}
.pcoded-navbar.navbar-red {
  background: #ff5252;
}
.pcoded-navbar.navbar-red .mobile-menu span {
  background-color: #fff;
}
.pcoded-navbar.navbar-red .mobile-menu span:after, .pcoded-navbar.navbar-red .mobile-menu span:before {
  background-color: #fff;
}
.pcoded-navbar.navbar-red .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.navbar-red .header-logo .b-bg {
  background: #ff5252;
}
.pcoded-navbar.navbar-red .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.navbar-red .navbar-brand,
.pcoded-navbar.navbar-red .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar.navbar-red .pcoded-menu-caption {
  color: #fff;
}
.pcoded-navbar.navbar-red .pcoded-inner-navbar li a {
  color: #fff;
}
.pcoded-navbar.navbar-red .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-red .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.navbar-red .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-red .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev {
  background: #ff5252;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-red.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}
.pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #ff5252;
}
.pcoded-navbar.navbar-purple {
  background: #9575CD;
}
.pcoded-navbar.navbar-purple .mobile-menu span {
  background-color: #fff;
}
.pcoded-navbar.navbar-purple .mobile-menu span:after, .pcoded-navbar.navbar-purple .mobile-menu span:before {
  background-color: #fff;
}
.pcoded-navbar.navbar-purple .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.navbar-purple .header-logo .b-bg {
  background: #9575CD;
}
.pcoded-navbar.navbar-purple .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.navbar-purple .navbar-brand,
.pcoded-navbar.navbar-purple .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar.navbar-purple .pcoded-menu-caption {
  color: #fff;
}
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li a {
  color: #fff;
}
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-purple .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.navbar-purple .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev {
  background: #9575CD;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-purple.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}
.pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #9575CD;
}
.pcoded-navbar.navbar-lightblue {
  background: #23b7e5;
}
.pcoded-navbar.navbar-lightblue .mobile-menu span {
  background-color: #fff;
}
.pcoded-navbar.navbar-lightblue .mobile-menu span:after, .pcoded-navbar.navbar-lightblue .mobile-menu span:before {
  background-color: #fff;
}
.pcoded-navbar.navbar-lightblue .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.navbar-lightblue .header-logo .b-bg {
  background: #23b7e5;
}
.pcoded-navbar.navbar-lightblue .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.navbar-lightblue .navbar-brand,
.pcoded-navbar.navbar-lightblue .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar.navbar-lightblue .pcoded-menu-caption {
  color: #fff;
}
.pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li a {
  color: #fff;
}
.pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.navbar-lightblue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-prev {
  background: #23b7e5;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-lightblue.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}
.pcoded-navbar.navbar-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #23b7e5;
}
.pcoded-navbar.navbar-dark {
  background: #323437;
}
.pcoded-navbar.navbar-dark .mobile-menu span {
  background-color: #fff;
}
.pcoded-navbar.navbar-dark .mobile-menu span:after, .pcoded-navbar.navbar-dark .mobile-menu span:before {
  background-color: #fff;
}
.pcoded-navbar.navbar-dark .mobile-menu.on span {
  background-color: transparent;
}
.pcoded-navbar.navbar-dark .header-logo .b-bg {
  background: #323437;
}
.pcoded-navbar.navbar-dark .header-logo .b-bg i {
  color: #fff;
  -webkit-text-fill-color: unset;
}
.pcoded-navbar.navbar-dark .navbar-brand,
.pcoded-navbar.navbar-dark .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar.navbar-dark .pcoded-menu-caption {
  color: #fff;
}
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li a {
  color: #fff;
}
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li.active > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li:focus > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.navbar-dark .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.navbar-dark .pcoded-inner-navbar > li.active > a, .pcoded-navbar.navbar-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(0, 0, 0, 0.08);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next,
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev {
  background: #323437;
  color: rgba(255, 255, 255, 0.8);
}
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-next:hover,
.pcoded-navbar.navbar-dark.theme-horizontal .sidenav-horizontal-prev:hover {
  color: #fff;
}
.pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active:before, .pcoded-navbar.navbar-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger:before {
  color: #323437;
}
.pcoded-navbar[class*='navbar-'] .pcoded-inner-navbar > li.active:after,
.pcoded-navbar[class*='navbar-'] .pcoded-navbar .pcoded-inner-navbar > li.pcoded-trigger:after {
  background: rgba(0, 0, 0, 0.35);
}

/* ====== [ Menu color end ] ======== */
/* ====== [ Active Color start ] ======== */
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea;
}
.pcoded-navbar.active-blue[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #04a9f5;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-blue .pcoded-inner-navbar li:hover > a {
  color: #04a9f5;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-blue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #04a9f5;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #04a9f5;
}
.pcoded-navbar.active-blue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-blue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #04a9f5;
}
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea;
}
.pcoded-navbar.active-red[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #ff5252;
}
.pcoded-navbar.active-red .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-red .pcoded-inner-navbar li:hover > a {
  color: #ff5252;
}
.pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-red .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #ff5252;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #ff5252;
}
.pcoded-navbar.active-red .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-red.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #ff5252;
}
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea;
}
.pcoded-navbar.active-purple[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #9575CD;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-purple .pcoded-inner-navbar li:hover > a {
  color: #9575CD;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-purple .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #9575CD;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #9575CD;
}
.pcoded-navbar.active-purple .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-purple.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #9575CD;
}
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea;
}
.pcoded-navbar.active-lightblue[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #23b7e5;
}
.pcoded-navbar.active-lightblue .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li:hover > a {
  color: #23b7e5;
}
.pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-lightblue .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #23b7e5;
}
.pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #23b7e5;
}
.pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar.active-lightblue.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-lightblue.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #23b7e5;
}
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #3ebfea;
}
.pcoded-navbar.active-dark[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li.active > a:before {
  color: #323437;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar li:focus > a, .pcoded-navbar.active-dark .pcoded-inner-navbar li:hover > a {
  color: #323437;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.active-dark .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #323437;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > li.active:after, .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger:after {
  background-color: #323437;
}
.pcoded-navbar.active-dark .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #333f54;
  color: #fff;
}
.pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: #f1f1f1;
  color: #111;
}
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu:hover > a {
  background: transparent;
}
.pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li .pcoded-submenu > li:hover > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.active > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li.pcoded-trigger > a, .pcoded-navbar.active-dark.theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu .pcoded-submenu > li:hover > a {
  color: #323437;
}

/* ====== [ Active Color end ] ======== */
/* ====== [ menu static start ] ======== */
.pcoded-navbar.menupos-static {
  position: absolute;
  height: auto;
  min-height: 100%;
}
.pcoded-navbar.menupos-static .navbar-wrapper,
.pcoded-navbar.menupos-static .scroll-div.navbar-content {
  height: auto;
  min-height: 100vh;
}

/* ====== [ menu static end ] ======== */
/* ====== [Boxc start ] ======== */
body.box-layout {
  position: static;
}
body.box-layout .pcoded-header {
  position: relative;
}
body.box-layout .pcoded-header.headerpos-fixed ~ .pcoded-main-container {
  padding-top: 0;
}
body.box-layout .pcoded-navbar.theme-horizontal ~ .pcoded-header {
  position: relative;
}
@media only screen and (max-width: 992px) {
  body.box-layout {
    position: relative;
  }
  body.box-layout.container {
    padding: 0;
  }
}

/* ====== [ Box end ] ======== */
/* ====== [ Header Fixed start ] ======== */
.pcoded-header.headerpos-fixed {
  position: fixed;
  top: 0;
}
.pcoded-header.headerpos-fixed ~ .pcoded-main-container {
  padding-top: 70px;
}
@media only screen and (max-width: 991px) {
  .pcoded-header.headerpos-fixed ~ .pcoded-main-container {
    padding-top: 140px;
  }
}

/* ====== [Header Fixed  end ] ======== */
/* ====== [ Dropdown icon start ] ======== */
.pcoded-navbar.drp-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e847";
}
.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu > a:after {
  content: "\e8b1";
}
.pcoded-navbar.drp-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu.pcoded-trigger > a:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

/* ====== [ Dropdown icon end ] =================== */
/* ====== [ Menu Item icon start ] ================ */
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  background: rgba(169, 183, 208, 0.05);
}
.pcoded-navbar[class*="menu-item-icon-style"] .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  font-family: 'feather';
  font-style: normal;
  padding-right: 4px;
  font-size: 13px;
  position: absolute;
  left: 35px;
  top: 10px;
  opacity: 1;
  visibility: visible;
  background: transparent;
}
.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu > .pcoded-submenu > li > a:before {
  left: 5px;
}
.pcoded-navbar[class*="menu-item-icon-style"].theme-horizontal .pcoded-inner-navbar > li.pcoded-hasmenu > .pcoded-submenu > li > .pcoded-submenu > li > a:before {
  top: 12px;
}
.pcoded-navbar.menu-item-icon-style2 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e897";
}
.pcoded-navbar.menu-item-icon-style3 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e83f";
}
.pcoded-navbar.menu-item-icon-style4 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e856";
}
.pcoded-navbar.menu-item-icon-style5 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e847";
}
.pcoded-navbar.menu-item-icon-style6 .pcoded-inner-navbar li.pcoded-hasmenu .pcoded-submenu li > a:before {
  content: "\e844";
}

/* ====== [ Menu Item icon end ] ================== */
/* ====== [ Menu background images start ] ======== */
.pcoded-navbar[class*="navbar-image"] {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.pcoded-navbar[class*="navbar-image"] .navbar-brand,
.pcoded-navbar[class*="navbar-image"] .pcoded-submenu {
  background: transparent;
}
.pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar > li.active > a, .pcoded-navbar[class*="navbar-image"] .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(51, 63, 84, 0.4);
}
.pcoded-navbar[class*="navbar-image"]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
}
/* .pcoded-navbar.navbar-image-1 {
  background-image: url("../images/nav-bg/navbar-img-1.jpg");
}
.pcoded-navbar.navbar-image-2 {
  background-image: url("../images/nav-bg/navbar-img-2.jpg");
}
.pcoded-navbar.navbar-image-3 {
  background-image: url("../images/nav-bg/navbar-img-3.jpg");
}
.pcoded-navbar.navbar-image-4 {
  background-image: url("../images/nav-bg/navbar-img-4.jpg");
}
.pcoded-navbar.navbar-image-5 {
  background-image: url("../images/nav-bg/navbar-img-5.jpg");
} */

/* ====== [ Menu background images end ] ========== */
/* ====== [ Menu title color start ] ============== */
.pcoded-navbar.title-blue .pcoded-inner-navbar .pcoded-menu-caption {
  color: #04a9f5;
}
.pcoded-navbar.title-red .pcoded-inner-navbar .pcoded-menu-caption {
  color: #ff5252;
}
.pcoded-navbar.title-purple .pcoded-inner-navbar .pcoded-menu-caption {
  color: #9575CD;
}
.pcoded-navbar.title-lightblue .pcoded-inner-navbar .pcoded-menu-caption {
  color: #23b7e5;
}
.pcoded-navbar.title-dark .pcoded-inner-navbar .pcoded-menu-caption {
  color: #323437;
}

/* ====== [ Menu title color end ] ================ */
/* ====== [ Hide Caption start ] ============== */
.pcoded-navbar.caption-hide .pcoded-menu-caption {
  display: none;
}

/* ====== [ Hide Caption end ] ================ */
/* ==========================    Diffrent Layout Styles end     ====================== */
/* temp SCSS for document */
.doc-img > a {
  position: relative;
  width: 130px;
  height: 80px;
  display: inline-block;
  border: 3px solid #fff;
  margin-right: 5px;
  background: #f4f7fa;
  overflow: hidden;
}
.doc-img > a span {
  width: 100%;
  position: absolute;
}
.doc-img > a span:after, .doc-img > a span:before {
  content: "";
  height: 100%;
  position: absolute;
}
.doc-img > a span:before {
  width: 30%;
  left: 0;
  background: #3f4d67;
}
.doc-img > a span:after {
  width: 70%;
  right: 0;
  background: #f4f7fa;
}
.doc-img > a > span:nth-child(1) {
  height: 20%;
  top: 0;
}
.doc-img > a > span:nth-child(2) {
  height: 80%;
  bottom: 0;
}
.doc-img > a.active {
  border-color: #04a9f5;
}
.doc-img > a.active:before {
  content: "\e83f";
  top: -4px;
  font-size: 20px;
  position: absolute;
  left: 10px;
  font-family: "feather";
  z-index: 1001;
  color: #04a9f5;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  text-shadow: 0 3px 8px rgba(4, 169, 245, 0.9), 0 3px 8px rgba(0, 0, 0, 0.1);
}
.doc-img > a.active:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.65);
}
.doc-img > a[data-value="menu-light"] span:before {
  background: #fff;
}
.doc-img > a[data-value="dark"] span:after {
  background: #101011;
}
.doc-img.navbar-color > a[data-value="navbar-blue"] > span:before {
  background: #04a9f5;
}
.doc-img.navbar-color > a[data-value="navbar-red"] > span:before {
  background: #ff5252;
}
.doc-img.navbar-color > a[data-value="navbar-purple"] > span:before {
  background: #9575CD;
}
.doc-img.navbar-color > a[data-value="navbar-lightblue"] > span:before {
  background: #23b7e5;
}
.doc-img.navbar-color > a[data-value="navbar-dark"] > span:before {
  background: #323437;
}
.doc-img.header-color > a[data-value="header-blue"] > span:nth-child(1):after {
  background: #04a9f5;
}
.doc-img.header-color > a[data-value="header-red"] > span:nth-child(1):after {
  background: #ff5252;
}
.doc-img.header-color > a[data-value="header-purple"] > span:nth-child(1):after {
  background: #9575CD;
}
.doc-img.header-color > a[data-value="header-lightblue"] > span:nth-child(1):after {
  background: #23b7e5;
}
.doc-img.header-color > a[data-value="header-dark"] > span:nth-child(1):after {
  background: #323437;
}
.doc-img.brand-color > a[data-value="brand-blue"] > span:nth-child(1):before {
  background: #04a9f5;
}
.doc-img.brand-color > a[data-value="brand-red"] > span:nth-child(1):before {
  background: #ff5252;
}
.doc-img.brand-color > a[data-value="brand-purple"] > span:nth-child(1):before {
  background: #9575CD;
}
.doc-img.brand-color > a[data-value="brand-lightblue"] > span:nth-child(1):before {
  background: #23b7e5;
}
.doc-img.brand-color > a[data-value="brand-dark"] > span:nth-child(1):before {
  background: #323437;
}
.doc-img.active-color > a[data-value="active-default"] {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.doc-img.active-color > a[data-value="active-blue"] {
  background: #04a9f5;
}
.doc-img.active-color > a[data-value="active-red"] {
  background: #ff5252;
}
.doc-img.active-color > a[data-value="active-purple"] {
  background: #9575CD;
}
.doc-img.active-color > a[data-value="active-lightblue"] {
  background: #23b7e5;
}
.doc-img.active-color > a[data-value="active-dark"] {
  background: #323437;
}
.doc-img.title-color > a[data-value="title-default"] {
  background: #fff;
}
.doc-img.title-color > a[data-value="title-blue"] {
  background: #04a9f5;
}
.doc-img.title-color > a[data-value="title-red"] {
  background: #ff5252;
}
.doc-img.title-color > a[data-value="title-purple"] {
  background: #9575CD;
}
.doc-img.title-color > a[data-value="title-lightblue"] {
  background: #23b7e5;
}
.doc-img.title-color > a[data-value="title-dark"] {
  background: #323437;
}
.doc-img.navbar-images > a {
  height: 130px;
  background-size: 37px;
  background-position: left top;
}
.doc-img.navbar-images > a span:before {
  background: transparent;
}
/* .doc-img.navbar-images > a[data-value="navbar-image-1"] {
  background-image: url("../images/nav-bg/navbar-img-1.jpg");
}
.doc-img.navbar-images > a[data-value="navbar-image-2"] {
  background-image: url("../images/nav-bg/navbar-img-2.jpg");
}
.doc-img.navbar-images > a[data-value="navbar-image-3"] {
  background-image: url("../images/nav-bg/navbar-img-3.jpg");
}
.doc-img.navbar-images > a[data-value="navbar-image-4"] {
  background-image: url("../images/nav-bg/navbar-img-4.jpg");
}
.doc-img.navbar-images > a[data-value="navbar-image-5"] {
  background-image: url("../images/nav-bg/navbar-img-5.jpg");
}
.doc-img.navbar-images > a[data-value="navbar-image-6"] {
  background-image: url("../images/nav-bg/navbar-img-6.jpg");
} */

.nav-link.active.h-blue {
  background: #04a9f5 !important;
}

.nav-link.active.h-red {
  background: #ff5252 !important;
}

.nav-link.active.h-purple {
  background: #9575CD !important;
}

.nav-link.active.h-lightblue {
  background: #23b7e5 !important;
}

.nav-link.active.h-dark {
  background: #323437 !important;
}

@media all and (-ms-high-contrast: none) {
  .pcoded-header .dropdown.show::before {
    line-height: 0.1;
  }

  .pcoded-header .navbar-nav > li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }

  .pcoded-header .navbar-nav > li.dropdown {
    display: inline-block;
  }

  .dropdown-menu.show::before {
    top: -2px;
  }

  .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu::after {
    left: 40px;
  }
}
/* ===================================================================================================
 ==========================    Diffrent types of NAvbar Layout Styles end     ======================
 =================================================================================================== */
.pcoded-navbar.layout-2 a, .pcoded-navbar.layout-2-2 a, .pcoded-navbar.layout-3 a, .pcoded-navbar.layout-4 a, .pcoded-navbar.layout-4-2 a {
  color: rgba(255, 255, 255, 0.85);
}
.pcoded-navbar.layout-2 .pcoded-submenu, .pcoded-navbar.layout-2-2 .pcoded-submenu, .pcoded-navbar.layout-3 .pcoded-submenu, .pcoded-navbar.layout-4 .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu {
  background-color: rgba(0, 0, 0, 0.05);
}
.pcoded-navbar.layout-2 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-2-2 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-3 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu .pcoded-submenu {
  background-color: transparent;
}
.pcoded-navbar.layout-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-2-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-3.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-4.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after, .pcoded-navbar.layout-4-2.navbar-collapsed .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu:after {
  display: none;
}
.pcoded-navbar.layout-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-2-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-3.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-4.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a, .pcoded-navbar.layout-4-2.navbar-collapsed:hover .pcoded-inner-navbar > li.pcoded-trigger .pcoded-submenu li a {
  color: rgba(255, 255, 255, 0.85);
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-3 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-4 .pcoded-inner-navbar .pcoded-menu-caption, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar .pcoded-menu-caption {
  display: none;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a {
  padding: 20px 15px;
  margin-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a .pcoded-badge, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a .pcoded-badge {
  top: 25px;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext {
  top: 25px;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-hasmenu > a:after {
  top: 25px;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active:after, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger:after {
  display: none;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger > a {
  background-color: rgba(0, 0, 0, 0.08);
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-2 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-3 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar li:hover > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li:focus > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li:hover > a {
  color: #fff;
}
.pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-2-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-3 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-4 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li.active > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li:focus > a:before, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar li .pcoded-submenu > li:hover > a:before {
  background: #fff;
}
.pcoded-navbar.layout-2 {
  background: -webkit-gradient(linear, left top, left bottom, from(253Deg), color-stop(0, #1de9b6), color-stop(70%, #1dc4e9));
  background: linear-gradient(253Deg, #1de9b6 0, #1dc4e9 70%);
}
.pcoded-navbar.layout-2-2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.pcoded-navbar.layout-2-2 .navbar-brand .b-brand .b-bg {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.pcoded-navbar.layout-3 {
  background: #04a9f5;
}
.pcoded-navbar.layout-4, .pcoded-navbar.layout-4-2 {
  background: #212224;
}
.pcoded-navbar.layout-4 a, .pcoded-navbar.layout-4-2 a {
  color: rgba(255, 255, 255, 0.5);
}
.pcoded-navbar.layout-4 .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu {
  background-color: rgba(255, 255, 255, 0.05);
}
.pcoded-navbar.layout-4 .pcoded-submenu .pcoded-submenu, .pcoded-navbar.layout-4-2 .pcoded-submenu .pcoded-submenu {
  background-color: transparent;
}
.pcoded-navbar.layout-4 .pcoded-inner-navbar > li > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.pcoded-navbar.layout-4 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4 .pcoded-inner-navbar > li.pcoded-trigger > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.active > a, .pcoded-navbar.layout-4-2 .pcoded-inner-navbar > li.pcoded-trigger > a {
  background-color: rgba(255, 255, 255, 0.08);
}
.pcoded-navbar.layout-4 .navbar-brand, .pcoded-navbar.layout-4-2 .navbar-brand {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.pcoded-navbar.layout-4 .navbar-brand .mobile-menu span,
.pcoded-navbar.layout-4 .navbar-brand .mobile-menu span:after,
.pcoded-navbar.layout-4 .navbar-brand .mobile-menu span:before, .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span,
.pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span:after,
.pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu span:before {
  background: #fff;
}
.pcoded-navbar.layout-4 .navbar-brand .mobile-menu.on span, .pcoded-navbar.layout-4-2 .navbar-brand .mobile-menu.on span {
  background: transparent;
}
.pcoded-navbar.layout-4 .navbar-brand .b-brand .b-bg, .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg {
  background: #fff;
}
.pcoded-navbar.layout-4 .navbar-brand .b-brand .b-bg i, .pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg i {
  color: #1de9b6;
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pcoded-navbar.layout-4-2 .navbar-brand {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg {
  background: #fff;
}
.pcoded-navbar.layout-4-2 .navbar-brand .b-brand .b-bg i {
  color: #1de9b6;
  background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body.layout-6 {
  background: #23b7e5;
  background-size: cover;
  background-attachment: fixed;
  background-position: top right;
  position: relative;
  /* for able pro style */
  /* dark navbar */
}
body.layout-6 .page-header-title + .breadcrumb > .breadcrumb-item:last-child a,
body.layout-6 .page-header-title h5,
body.layout-6 .pcoded-header .input-group .input-group-text,
body.layout-6 .pcoded-header .navbar-nav > li > .dropdown > .dropdown-toggle,
body.layout-6 .pcoded-header .navbar-nav > li > .dropdown-toggle,
body.layout-6 .pcoded-header .navbar-nav > li > a {
  color: #fff;
}
body.layout-6 .mobile-menu span,
body.layout-6 .mobile-menu span:after,
body.layout-6 .mobile-menu span:before,
body.layout-6 .pcoded-navbar.menu-light .mobile-menu span,
body.layout-6 .pcoded-navbar.menu-light .mobile-menu span:after,
body.layout-6 .pcoded-navbar.menu-light .mobile-menu span:before {
  background: #fff;
}
body.layout-6 .breadcrumb-item + .breadcrumb-item::before,
body.layout-6 .page-header-title + .breadcrumb > .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.8);
}
body.layout-6 .pcoded-content {
  background: #f4f7fa;
}
body.layout-6 .navbar-brand,
body.layout-6 .pcoded-header.header-blue,
body.layout-6 .pcoded-navbar,
body.layout-6 .pcoded-navbar.menu-light,
body.layout-6 .pcoded-navbar.menu-light .header-logo {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
body.layout-6 .pcoded-navbar .slimScrollDiv {
  background: #3f4d67;
  border-radius: 0 10px 0 0;
  -webkit-box-shadow: 0 0 20px 0 #3f4d67;
          box-shadow: 0 0 20px 0 #3f4d67;
}
body.layout-6.box-layout .pcoded-navbar .slimScrollDiv {
  border-radius: 10px 10px 0 0;
}
body.layout-6 .main-body {
  min-height: calc(100vh - 200px);
}
body.layout-6 .pcoded-navbar.menu-light .slimScrollDiv {
  background: #fff;
  -webkit-box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
          box-shadow: 7px 0 15px 0 rgba(69, 90, 100, 0.09);
}
body.layout-6 .pcoded-navbar.menu-light .slimScrollDiv .slimScrollBar {
  z-index: 1028 !important;
}
body.layout-6 .pcoded-navbar.mob-open ~ .pcoded-header:before,
body.layout-6 .pcoded-navbar.mob-open ~ .pcoded-main-container:before,
body.layout-6 .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-header:before,
body.layout-6 .pcoded-navbar.navbar-collapsed:hover ~ .pcoded-main-container:before {
  background: transparent;
}
body.layout-6 .pcoded-navbar.menupos-static .navbar-wrapper,
body.layout-6 .pcoded-navbar.menupos-static .scroll-div.navbar-content {
  position: absolute;
  height: 100%;
}
body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .b-brand {
  display: none;
}
body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu {
  right: auto;
  left: 20px;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
body.layout-6 .pcoded-navbar.navbar-collapsed:hover .navbar-brand .mobile-menu span {
  background: transparent;
}
body.layout-6 .pcoded-content {
  margin-top: 200px;
}
body.layout-6 .pcoded-content .pcoded-inner-content {
  margin-top: -200px;
  min-height: calc(100vh + 150px);
}
body.layout-6 .card {
  border-radius: 5px;
}
body.layout-6 .pcoded-navbar .header-logo {
  background: transparent;
}
body.layout-6 .pcoded-navbar.navbar-dark.brand-lightblue .header-logo, body.layout-6 .pcoded-navbar.navbar-dark[class*="navbar-"].brand-lightblue .header-logo,
body.layout-6 .pcoded-navbar.navbar-dark .header-logo {
  background: transparent;
}
body.layout-6 .pcoded-navbar.navbar-dark .slimScrollDiv {
  background: #323437;
  -webkit-box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.08);
          box-shadow: 2px 0 20px 0 rgba(0, 0, 0, 0.08);
}
@media only screen and (max-width: 991px) {
  body.layout-6 .pcoded-header .input-group .input-group-text,
  body.layout-6 .pcoded-header .navbar-nav > li > .dropdown > .dropdown-toggle,
  body.layout-6 .pcoded-header .navbar-nav > li > .dropdown-toggle,
  body.layout-6 .pcoded-header .navbar-nav > li > a {
    color: #3f4d67;
  }
}

body.layout-8 .pcoded-header {
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
          box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
}
body.layout-8 .pcoded-navbar.menu-light {
  -webkit-box-shadow: inset -1px 0 0 0 rgba(100, 121, 143, 0.122);
          box-shadow: inset -1px 0 0 0 rgba(100, 121, 143, 0.122);
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-submenu {
  background: transparent;
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-menu-caption:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 20px;
  height: 1px;
  width: calc(100% - 40px);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
          box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.122);
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-menu-caption:first-child:after {
  display: none;
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a {
  border-radius: 0 20px 20px 0;
  margin-right: 15px;
  padding: 4px 15px;
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a:after {
  top: 8px;
  right: 15px;
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li > a > .pcoded-micon + .pcoded-mtext {
  top: 9px;
}
body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(35, 183, 229, 0.15);
  color: #23b7e5;
}
body.layout-8 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a {
  margin-right: 8px;
}
body.layout-8 .pcoded-navbar.navbar-collapsed .pcoded-inner-navbar > li > a:after {
  top: 8px;
  right: 15px;
}
body.layout-8 .pcoded-navbar.active-blue .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-blue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(4, 169, 245, 0.15);
  color: #04a9f5;
}
body.layout-8 .pcoded-navbar.active-red .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-red .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(255, 82, 82, 0.15);
  color: #ff5252;
}
body.layout-8 .pcoded-navbar.active-purple .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-purple .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(149, 117, 205, 0.15);
  color: #9575CD;
}
body.layout-8 .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-lightblue .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(35, 183, 229, 0.15);
  color: #23b7e5;
}
body.layout-8 .pcoded-navbar.active-dark .pcoded-inner-navbar > li.active > a, body.layout-8 .pcoded-navbar.active-dark .pcoded-inner-navbar > li.pcoded-trigger > a {
  background: rgba(50, 52, 55, 0.15);
  color: #323437;
}
body.layout-8 .card {
  -webkit-box-shadow: 0 0px 0 1px rgba(100, 121, 143, 0.122);
          box-shadow: 0 0px 0 1px rgba(100, 121, 143, 0.122);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
body.layout-8 .card:hover {
  -webkit-box-shadow: 0 0px 0 1px rgba(100, 121, 143, 0.122), 0 2px 1px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0px 0 1px rgba(100, 121, 143, 0.122), 0 2px 1px rgba(0, 0, 0, 0.05);
}

.gradientcolor .btn-round {
  border-radius: 30px;
}

.ChartShadow {
  -webkit-filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 20px 8px rgba(0, 0, 0, 0.2));
}

.chart-activity,
.chart-sale,
.chart-statistics,
.device-chart {
  margin: 0 auto;
}

#Statistics-sale .amcharts-cursor-fill {
  -webkit-filter: url(#shadow);
          filter: url(#shadow);
  fill: rgba(255, 255, 255, 0.3);
  stroke-width: 0;
}

.amcharts-zoom-out-bg,
.amcharts-zoom-out-image {
  display: none;
}

/* statistics section */
.card-command .card-icon {
  opacity: 0.5;
}

.mat-drp .btn.dropdown-toggle {
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #d8d8d8;
}
.mat-drp .btn.dropdown-toggle:after {
  display: none;
}
.mat-drp .btn.dropdown-toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.table-card .row-table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
}
.table-card .row-table i {
  padding: 50px 20px;
}
.table-card .row-table > [class*=col-] {
  display: table-cell;
  float: none;
  table-layout: fixed;
  vertical-align: middle;
}
.table-card .row-table > [class*=col-] .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.rides-bar i.rides-icon {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 15px;
}

.visitor .img-female {
  position: absolute;
  left: 10px;
  bottom: 0;
}
.visitor .img-men {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.profit-bar i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border-radius: 50px;
  padding: 13px;
}

.assets-value .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center 103%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
}
.assets-value .card-block {
  position: relative;
  z-index: 5;
}
@media screen and (max-width: 1199px) {
  .assets-value .bg-img {
    background: none;
  }
}

.chat-sanders .card-header {
  background: linear-gradient(-135deg, #3f4d67 0%, #3f4d67 100%);
}
.chat-sanders .msg {
  margin-bottom: 5px;
  display: inline-block;
  padding: 15px;
  position: relative;
}
.chat-sanders .scroll-wrapper {
  height: 305px;
}
.chat-sanders .received-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .received-chat .msg {
  background: #fff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .received-chat .msg:after {
  content: "";
  position: absolute;
  left: -12px;
  bottom: 18px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  border: 6px solid transparent;
  border-bottom-color: #fff;
}
.chat-sanders .send-chat h5 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
}
.chat-sanders .send-chat .msg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
.chat-sanders .send-chat .msg:after {
  content: "";
  position: absolute;
  right: -11px;
  bottom: 18px;
  -webkit-transform: rotate(450deg);
          transform: rotate(450deg);
  border: 6px solid transparent;
  border-bottom-color: #1de9b6;
}
.chat-sanders .btn {
  background: none;
  opacity: 0.4;
}
.chat-sanders .form-control {
  background: #f4f7fa;
}
.chat-sanders .input-group {
  background: transparent;
}

.widget-menu {
  background: linear-gradient(-135deg, #88d3ce 0%, #6e45e2 100%);
}
.widget-menu .widget-title {
  border-top: 1px solid #676fb9;
}
.widget-menu i {
  opacity: 0.5;
}

.to-do .to-do-button {
  position: absolute;
  bottom: 30px;
  right: 20px;
}
.to-do .to-do-button .btn {
  width: 40px;
  height: 40px;
  float: right;
  border-radius: 50px;
  border: none;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
}

.lazy-dog i {
  opacity: 0.5;
}

.widget-content .widget-lorem .media h5 {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
}

.note-bar .friendlist-box {
  padding: 25px 35px;
  border-top: 1px solid #e3e3e3;
}
.note-bar .friendlist-box:first-child {
  border: 0;
}
.note-bar .friendlist-box h6 {
  display: inline-block;
}
.note-bar .friendlist-box i {
  opacity: 0.2;
  color: #888;
}

.to-do-list h6 {
  display: inline-block;
}
.to-do-list .done-task {
  opacity: 0.7;
}
.to-do-list .done-task > div {
  -webkit-filter: grayscale(0.8);
          filter: grayscale(0.8);
}
.to-do-list .done-task h6,
.to-do-list .done-task p,
.to-do-list .done-task span {
  text-decoration: line-through;
}
.to-do-list .checkbox-fade .check-task {
  display: block;
}
.to-do-list .checkbox-fade .to-content {
  display: inline-block;
}
.to-do-list .checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin: 0;
  top: 5px;
}
.to-do-list .checkbox-fade .cr .cr-icon {
  color: #fff;
  font-size: 0.8em;
  left: 0;
  line-height: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
}
.to-do-list .checkbox-fade label input[type=checkbox] {
  display: none;
}
.to-do-list .checkbox-fade label input[type=checkbox] + .cr > .cr-icon {
  -webkit-transform: scale(3) rotateZ(-20deg);
          transform: scale(3) rotateZ(-20deg);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr > .cr-icon {
  -webkit-transform: scale(1) rotateZ(0deg);
          transform: scale(1) rotateZ(0deg);
  opacity: 1;
}
.to-do-list .checkbox-fade label input[type=checkbox]:checked + .cr {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  border: 0;
}

.User-Activity .table td,
.user-list .table td {
  vertical-align: middle;
}
.User-Activity .table thead th,
.user-list .table thead th {
  border: 0;
}

.Application-list .table td,
.code-table .table td {
  vertical-align: middle;
}
.Application-list .table .label,
.code-table .table .label {
  border-radius: 15px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.Application-list thead th,
.code-table thead th {
  border: 0;
}

.Recent-Users .table tr:first-child td,
.User-Lists .table tr:first-child td {
  border-top: 0;
}
.Recent-Users .table td,
.User-Lists .table td {
  vertical-align: middle;
}
.Recent-Users .table .label,
.User-Lists .table .label {
  border-radius: 15px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.social-media .progress {
  background: transparent;
  border-radius: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.social-media .progress h5 {
  position: relative;
  top: -2px;
}

.post-emoticon ul {
  padding: 0;
  margin: 25px 0 0;
  list-style: none;
}
.post-emoticon li {
  display: inline-block;
}
.post-emoticon i {
  position: relative;
  top: 4px;
}

.visa-top .visa img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.loction-user .row {
  padding: 35px 30px;
}
.loction-user i {
  opacity: 0.5;
}
.loction-user .loction-progress {
  padding: 35px 30px;
}
.loction-user .loction-progress .progress {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 10px;
  border-radius: 0;
}

.Design-sprint .design-image,
.dashboard-kit .design-image {
  padding: 0;
  margin: 30px 0 0;
  list-style: none;
}
.Design-sprint li,
.dashboard-kit li {
  display: inline-block;
  text-align: center;
  margin-right: 3px;
}
.Design-sprint .btn,
.dashboard-kit .btn {
  border-radius: 50px;
  border: 0;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.Design-sprint i,
.dashboard-kit i {
  opacity: 0.3;
}

.ux-designer {
  position: relative;
  padding: 35px 30px;
}
.ux-designer .btn {
  border-radius: 50px;
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 20px;
  top: -20px;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.task-list {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 30px 0 0;
}
.task-list:after {
  content: "";
  position: absolute;
  background: #ecedef;
  height: 100%;
  width: 2px;
  top: 0;
  left: 30px;
  z-index: 1;
}
.task-list li {
  margin-bottom: 30px;
  padding-left: 55px;
  position: relative;
}
.task-list li:last-child {
  margin-bottom: 0;
}
.task-list li .task-icon {
  position: absolute;
  left: 22px;
  top: 13px;
  border-radius: 50%;
  padding: 2px;
  width: 17px;
  height: 17px;
  z-index: 2;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-main {
  padding: 30px 25px;
}
.project-main i {
  opacity: 0.4;
}

.user-chart i {
  opacity: 0.3;
}

.leads-progress .progress,
.progress-gender .progress {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 10px;
  border-radius: 0;
}

.user-card .label {
  border-radius: 15px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.Active-visitor .progress {
  height: 7px;
}
.Active-visitor .card-active > div + div {
  border-left: 1px solid #eaeaea;
}
@media screen and (max-width: 767px) {
  .Active-visitor .card-active [class*=col-]:last-child {
    border-left: 0;
    margin-top: 20px;
  }
}

.Invoice-bar .invoice-lable label {
  background: #fff;
  border-radius: 15px;
  padding: 4px 20px;
}
.Invoice-bar i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.Invoice-bar .progress {
  background: rgba(255, 255, 255, 0.22);
  border-radius: 0;
  height: 7px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.location-sale .card-icon {
  width: 70px;
  height: 70px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: -20px;
  text-align: center;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 768px) {
  .location-sale .card-icon {
    display: none;
  }
}

.card-Impression i {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  width: 52px;
  height: 52px;
  border-radius: 5px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.card-Revenue i {
  background: rgba(255, 255, 255, 0.2);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card-customer i {
  width: 70px;
  height: 70px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 375px) {
  .card-customer i {
    width: 80px;
    height: 80px;
    font-size: 50px;
  }
}

.ticket-customer i {
  position: absolute;
  opacity: 0.2;
  right: 32px;
  bottom: 30px;
}

.ticket-visitor .progress {
  border-radius: 0;
  height: 13px;
}

.customer-visitor i {
  opacity: 0.2;
  font-size: 118px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.card-social:hover i {
  font-size: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.card-social .progress {
  height: 6px;
}
.card-social .card-active > div + div {
  border-left: 1px solid #eaeaea;
}

.Online-Order .progress,
.statistial-visit .progress {
  height: 6px;
}

.team-leader .slide {
  margin-bottom: 32px;
}
.team-leader .slide li {
  width: 10px;
  height: 10px;
  background: #e3e3e3;
  margin: 0 6px;
  border-radius: 50px;
}
.team-leader .slide .carousel-indicators {
  bottom: -44px;
}
.team-leader .slide .carousel-indicators .active {
  background: #869791;
}

.affilate-offers .card-icon {
  opacity: 0.5;
}
.affilate-offers .label {
  border-radius: 15px;
  margin: 0;
  padding: 8px 18px;
}

.earning-date .bd-example {
  padding: 0;
  margin: 0;
  border: 0;
}
.earning-date .bd-example .nav-pills {
  background: transparent;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.earning-date .bd-example .nav-pills .nav-link {
  min-width: auto;
  padding: 6px 20px;
  color: #fff;
}
.earning-date .bd-example .nav-pills .nav-link.active {
  background: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #000;
}
.earning-date .bd-example .nav-pills .nav-link.active:after {
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  top: -5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 11px;
}
.earning-date .bd-example .tab-content {
  background: transparent;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.sale-view i {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.project-task i {
  opacity: 0.5;
}
.project-task .label {
  border-radius: 15px;
  margin: 0;
  padding: 6px 16px;
}
.project-task .progress {
  height: 6px;
}

.card-event .label {
  border-radius: 15px;
  margin: 0;
  padding: 5px 16px;
}
.card-event i {
  position: absolute;
  bottom: 36px;
  right: 27px;
}

.bitcoin-wallet i {
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.5;
}

.summary-box .card-icon {
  opacity: 0.4;
}

.feature-card-box .feature-icon {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  color: #fff;
  margin: 0 auto;
  padding: 10px;
  font-size: 35px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.feature-card-box:hover .feature-icon {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.card .card-block code {
  background-color: #eee;
  margin: 5px;
  display: inline-block;
  border-radius: 3px;
  padding: 0 3px;
}

@media screen and (max-width: 767px) {
  .user-designer [class*=col-]:last-child {
    margin-top: 15px;
  }

  div.dataTables_wrapper div.dataTables_filter label {
    float: none !important;
    text-align: center;
  }
}
.offline-box iframe {
  width: 100%;
  border: 1px solid #ddd;
}

/* ===================== to do page ============================= */
#task-container ul {
  overflow: hidden;
  padding: 0;
}
#task-container li {
  float: left;
  width: 49%;
  overflow: auto;
  height: auto;
  min-height: 10px;
  background: #fff;
  display: inline-block;
  padding: 20px;
  border: 1px solid #ccc;
  color: #666;
  border-top: 5px solid #04a9f5;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
#task-container li:nth-child(2n) {
  margin-right: 0;
}
#task-container li.complete {
  opacity: 1;
  border-top: 9px solid #f44236;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}
#task-container li.complete:before {
  /* background: url("../images/complete.png") no-repeat; */
  position: absolute;
  top: 5px;
  right: 5px;
  content: "";
  width: 55px;
  height: 55px;
  background-size: 100%;
}
#task-container li.complete p {
  text-decoration: line-through;
}
@media screen and (max-width: 580px) {
  #task-container li {
    width: 100%;
  }
}

.new-task .to-do-list .cr {
  float: left;
  top: 0;
  margin-right: 16px;
}
.new-task label input[type=checkbox] {
  display: none;
}

.checkbox-fade .cr {
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 24px;
  margin-right: 0.5em;
  position: relative;
  width: 24px;
  margin-right: 16px;
  top: 0;
}

.task-panel .to-do-label {
  margin-bottom: 15px;
}
.task-panel .to-do-label:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding: 0;
}

.done-task .captions,
.done-task span {
  color: #919aa3;
  text-decoration: line-through;
}

.to-do-list i {
  color: #ccc;
  font-size: 17px;
  opacity: 0;
}
.to-do-list:hover i {
  opacity: 1;
  -webkit-transition: opacity ease-in 0.3s;
  transition: opacity ease-in 0.3s;
}

.note-card .note-box-aside {
  border-right: 1px solid #ddd;
}
.note-card .Note-header {
  padding: 20px 0;
}
.note-card #Note-pad {
  border: none;
  resize: none;
  background: 0 0;
  padding: 0 20px 0 50px;
  line-height: 35px;
}
.note-card .note-write {
  position: relative;
  background: -webkit-linear-gradient(top, #98dcfa 0%, #e1f5fe 5%) 0 0;
  background-size: 100% 35px;
}
.note-card .note-write:before {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 32px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .note-write:after {
  content: '';
  position: absolute;
  width: 0;
  top: 0;
  left: 34px;
  bottom: 0;
  border-left: 1px solid #4fc3f7;
}
.note-card .Note-delete {
  margin-bottom: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  position: absolute;
  right: 20px;
  color: #fff;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
}
.note-card #Note-list li:hover .Note-delete {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

/* ===================== Gallery page ============================= */
.gallery-masonry .card-columns {
  -webkit-column-count: 4;
          column-count: 4;
}

@media screen and (max-width: 1400px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media screen and (max-width: 992px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 2;
            column-count: 2;
  }
}
@media screen and (max-width: 575px) {
  .gallery-masonry .card-columns {
    -webkit-column-count: 1;
            column-count: 1;
  }
}
.job-meta-data i {
  margin-right: 5px;
  color: #04a9f5;
}

/* ===================== Task-list page ============================= */
.task-data img {
  width: 40px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.task-data i {
  color: #111;
}
.task-data .dropdown-toggle:after {
  color: #111;
}

.task-board-left .task-right-header-revision,
.task-board-left .task-right-header-status,
.task-board-left .task-right-header-users {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
.task-board-left .taskboard-right-progress,
.task-board-left .taskboard-right-revision,
.task-board-left .taskboard-right-users {
  padding-top: 10px;
}
.task-board-left .taskboard-right-progress .progress {
  height: 9px;
  margin-bottom: 25px;
}
.task-board-left .user-box .media-object {
  height: 50px;
  width: 50px;
  cursor: pointer;
}
.task-board-left .user-box .media-left {
  position: relative;
}
.task-board-left .user-box .btn.btn-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.task-board-left .live-status {
  height: 8px;
  width: 8px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  top: 5px;
}

.filter-bar .navbar {
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
          box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  padding: 0.5rem 1rem;
}
@media screen and (max-width: 557px) {
  .filter-bar .navbar .f-text {
    display: block;
    width: 100%;
  }
  .filter-bar .navbar .f-view {
    padding-left: 24px;
  }
  .filter-bar .navbar .f-view span {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
}
.filter-bar .label {
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 7px;
}
.filter-bar .task-detail {
  margin-bottom: 5px;
}
.filter-bar .card-task .task-list-table {
  display: inline-block;
}
.filter-bar .card-task .task-list-table img {
  width: 40px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.filter-bar .card-task .task-list-table i {
  color: #111;
}
.filter-bar .card-task .task-board {
  float: right;
  margin-top: 5px;
}
.filter-bar .card-task .task-board .dropdown {
  display: inline-block;
}
.filter-bar .card-task .task-board .btn {
  padding: 4px 10px;
  font-size: 10px;
  margin: 0;
}

.assign-user img,
.task-comment img {
  width: 45px;
  -webkit-box-shadow: none;
          box-shadow: none;
}

/* ===================== Invoice page ============================= */
.invoice-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  padding-top: 30px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.invoice-table.table {
  padding-left: 20px;
}
.invoice-table.table td {
  border: 0;
  padding: 4px 0;
}

.invoive-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}
.invoive-info h6 {
  margin-bottom: 20px;
  text-transform: uppercase;
}
.invoive-info .invoice-order.table {
  padding-left: 0;
}
.invoive-info .invoice-order.table th {
  border: 0;
  padding: 4px 0;
}
.invoive-info .invoice-order.table th:first-child {
  padding-left: 0;
  width: 80px;
}

.invoice-total.table {
  background: #f3f3f3;
  padding: 30px 0;
}
.invoice-total.table th {
  border: 0;
  padding: 4px 0;
  text-align: right;
}
.invoice-total.table td {
  text-align: right;
}
.invoice-total.table tbody {
  padding-right: 20px;
  float: right;
}

.invoice-summary .label {
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 12px;
}

.invoice-list .btn {
  padding: 5px 10px;
  font-size: 12px;
}
.invoice-list .task-list-table {
  display: inline-block;
}

/*  ================ new css =================== */
.datepicker-dropdown {
  padding: 20px;
  color: #fff;
  background: #3f4d67;
  font-size: 14px;
}
.datepicker-dropdown:after {
  border-bottom: 6px solid #3f4d67;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #3f4d67;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.highlighted.active, .datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active, .datepicker table tr td.highlighted,
.datepicker table tr td.today, .datepicker table tr td.day:hover, .datepicker table tr td.focused, .datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background-color: #333f54;
  color: #fff;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover,
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #ffffff94;
}

.syntax-output {
  border-radius: 0.25rem;
  border: 1px solid #eaeaea;
}

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
  padding: 3px 9px !important;
  background-color: #04a9f5;
  color: #fff;
}
.owl-carousel button:focus {
  outline: none;
  color: #fff;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #04a9f5;
  color: #fff;
}

.counter b {
  font-size: 24px;
}

.tour-mobile .error-block {
  display: none;
}

.message-mobile span {
  font-size: 16px;
}
.message-mobile .task-right-header-status {
  margin-bottom: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .message-mobile .task-right-header-status {
    display: none;
  }
}

.fullcalendar-card .fc-button {
  background-color: #04a9f5;
  border-color: #fff;
  color: #fff;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-shadow: none;
  height: 37px;
  padding: 0 15px;
}
.fullcalendar-card h2 {
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .fullcalendar-card .fc .fc-toolbar > * > * {
    float: none;
  }
  .fullcalendar-card .fc-toolbar .fc-left, .fullcalendar-card .fc-toolbar .fc-right, .fullcalendar-card .fc-toolbar .fc-center {
    float: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ck-content strong {
  font-weight: 600;
}

div.code-toolbar > .toolbar a, div.code-toolbar > .toolbar button, div.code-toolbar > .toolbar span {
  padding: 3px 0.9em !important;
  background: #04a9f5 !important;
  color: #fff !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

pre[class*=language-]:after, pre[class*=language-]:before {
  display: none;
}

@media screen and (max-width: 1199px) {
  .note-card .note-box-aside {
    border: none !important;
  }
}
@media screen and (max-width: 991px) {
  .ch-block {
    display: none;
  }

  .msg-block.dis-chat .taskboard-right-progress {
    display: none;
  }
  .msg-block.dis-chat .ch-block {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .tour-mobile .error-block {
    display: block;
  }

  .tour-mobile .page-wrapper {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .horizontal-mobile {
    display: none;
  }
}
/**  =====================
      Form Componant css start
==========================  **/
.custom-select,
.form-control {
  background: #f4f7fa;
  padding: 10px 20px;
  font-size: 14px;
}
.custom-select:focus,
.form-control:focus {
  background: #f4f7fa;
}
.custom-select::-moz-placeholder,
.form-control::-moz-placeholder {
  color: #aaaeb3;
  opacity: 1;
}
.custom-select:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: #aaaeb3;
}
.custom-select::-webkit-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #aaaeb3;
}

/* input group start */
.input-group {
  background: #f4f7fa;
}
.input-group .input-group-text {
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
}
.input-group .input-group-text i {
  font-size: 20px;
}
.input-group .custom-file-label {
  margin-bottom: 0;
}
.input-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5;
}
.cust-file-button .custom-file-label::after {
  padding: 0.775rem 0.75rem;
  height: 2.65rem;
}

.custom-select {
  height: calc(2.25rem + 9px);
}

/* input group End */
/**====== Form Componant css end ======**/
select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors .form-control {
  padding: 6px 12px 5px 44px;
}

/* form-select */
.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

/**  =====================
      Radio & Checked css start
==========================  **/
.checkbox {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.checkbox input[type=checkbox] {
  margin: 0;
  display: none;
  width: 22px;
}
.checkbox input[type=checkbox] + .cr {
  padding-left: 0;
}
.checkbox input[type=checkbox] + .cr:before {
  content: "\e83f";
  width: 22px;
  height: 22px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'feather';
  font-weight: 400;
  line-height: 19px;
  vertical-align: bottom;
  text-align: center;
  background: #ffffff;
  color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox input[type=checkbox]:checked + .cr:before {
  background: #1dd5d2;
  border-color: #1dd5d2;
  color: #ffffff;
}
.checkbox input[type=checkbox].disabled + .cr, .checkbox input[type=checkbox]:disabled + .cr {
  opacity: 0.5;
}
.checkbox input[type=checkbox].disabled + .cr:before, .checkbox input[type=checkbox]:disabled + .cr:before {
  cursor: not-allowed;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:after {
  content: "";
  width: 22.5px;
  height: 22.5px;
  display: inline-block;
  margin-right: 10px;
  border: 2px solid #e9eaec;
  border-radius: 2px;
  vertical-align: bottom;
  text-align: center;
  background: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 8.5px;
  left: 3px;
}
.checkbox.checkbox-fill input[type=checkbox] + .cr:before {
  opacity: 0;
  content: "\e840";
  font-size: 27px;
  background: transparent;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:after {
  opacity: 0;
}
.checkbox.checkbox-fill input[type=checkbox]:checked + .cr:before {
  opacity: 1;
  background: transparent;
  color: #1dd5d2;
  border-color: transparent;
}
.checkbox.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: #04a9f5;
  border-color: #04a9f5;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-primary input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #04a9f5;
  border-color: transparent;
}
.checkbox.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: #f44236;
  border-color: #f44236;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-danger input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f44236;
  border-color: transparent;
}
.checkbox.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: #1de9b6;
  border-color: #1de9b6;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-success input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #1de9b6;
  border-color: transparent;
}
.checkbox.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: #f4c22b;
  border-color: #f4c22b;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-warning input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #f4c22b;
  border-color: transparent;
}
.checkbox.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: #3ebfea;
  border-color: #3ebfea;
  color: #ffffff;
}
.checkbox.checkbox-fill.checkbox-info input[type=checkbox]:checked + .cr:before {
  background: transparent;
  color: #3ebfea;
  border-color: transparent;
}
.checkbox .cr {
  cursor: pointer;
}

.radio {
  padding: 10px 0;
  min-height: auto;
  position: relative;
  margin-right: 5px;
}
.radio input[type=radio] {
  margin: 0;
  display: none;
  width: 22px;
}
.radio input[type=radio] + .cr {
  padding-left: 0;
}
.radio input[type=radio] + .cr:after, .radio input[type=radio] + .cr:before {
  content: "";
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
  vertical-align: bottom;
  background: #fff;
  color: transparent;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.radio input[type=radio] + .cr:before {
  width: 22px;
  height: 22px;
  border: 2px solid #e9eaec;
}
.radio input[type=radio] + .cr:after {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 13px;
  left: 5px;
}
.radio input[type=radio]:checked + .cr:before {
  border-color: #1dd5d2;
}
.radio input[type=radio]:checked + .cr:after {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.radio input[type=radio]:disabled + .cr {
  opacity: 0.5;
  cursor: not-allowed;
}
.radio input[type=radio]:disabled + .cr:after, .radio input[type=radio]:disabled + .cr:before {
  cursor: not-allowed;
}
.radio.radio-fill input[type=radio] + .cr:after {
  width: 18px;
  height: 18px;
  top: 10px;
  left: 2px;
}
.radio.radio-primary input[type=radio]:checked + .cr:before {
  border-color: #04a9f5;
}
.radio.radio-primary input[type=radio]:checked + .cr:after {
  background: #04a9f5;
}
.radio.radio-danger input[type=radio]:checked + .cr:before {
  border-color: #f44236;
}
.radio.radio-danger input[type=radio]:checked + .cr:after {
  background: #f44236;
}
.radio.radio-success input[type=radio]:checked + .cr:before {
  border-color: #1de9b6;
}
.radio.radio-success input[type=radio]:checked + .cr:after {
  background: #1de9b6;
}
.radio.radio-warning input[type=radio]:checked + .cr:before {
  border-color: #f4c22b;
}
.radio.radio-warning input[type=radio]:checked + .cr:after {
  background: #f4c22b;
}
.radio.radio-info input[type=radio]:checked + .cr:before {
  border-color: #3ebfea;
}
.radio.radio-info input[type=radio]:checked + .cr:after {
  background: #3ebfea;
}
.radio .cr {
  cursor: pointer;
}

@-moz-document url-prefix() {
  .radio input[type="radio"] + .cr::after {
    top: 14px;
  }
}
.custom-controls-stacked .radio input[type=radio] + .cr:after {
  top: 15px;
}

/**====== Radio & Checked css end ======**/
/**  =====================
      Label & Badges css start
==========================  **/
.label {
  padding: 4px 10px;
  min-height: auto;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
}
.label.label-primary {
  background: #04a9f5;
  color: #ffffff;
}
.label.label-danger {
  background: #f44236;
  color: #ffffff;
}
.label.label-success {
  background: #1de9b6;
  color: #ffffff;
}
.label.label-warning {
  background: #f4c22b;
  color: #ffffff;
}
.label.label-info {
  background: #3ebfea;
  color: #ffffff;
}

/**====== Label & Badges css end ======**/
/**  =====================
      Data Tables css start
==========================  **/
.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem 0.75rem;
}
.table thead th {
  border-bottom: 1px solid #eaeaea;
}
.table tbody + tbody {
  border-top: 2px solid #eaeaea;
}

/* Border versions */
.table-bordered {
  border: 1px solid #eaeaea;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #eaeaea;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(4, 169, 245, 0.05);
}

/* Table backgrounds */
.table .thead-dark th {
  color: #fff;
  background-color: #37474f;
  border-color: #222c31;
}

.table-dark {
  color: #fff;
  background-color: #37474f;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #222c31;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #334249;
}
.table-dark.table-hover tbody tr:hover {
  background-color: #2d3940;
}

/* fixed header position */
table.dataTable.fixedHeader-floating {
  top: 0 !important;
}
@media screen and (max-width: 992px) {
  table.dataTable.fixedHeader-floating {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .fixedHeader-locked {
    display: none !important;
  }
}
/**  =====================
      Foo-table css start
==========================  **/
.footable .pagination > .active > a,
.footable .pagination > .active > span {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > .active > a:focus, .footable .pagination > .active > a:hover,
.footable .pagination > .active > span:focus,
.footable .pagination > .active > span:hover {
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.footable .pagination > li > a,
.footable .pagination > li > span {
  color: #222;
}

.footable-details.table,
.footable.table {
  margin-bottom: 0;
}

table.footable > tfoot > tr.footable-paging > td > span.label {
  margin-bottom: 0;
}
table.footable-paging-center > tfoot > tr.footable-paging > td {
  padding-bottom: 0;
}

.table-columned > tbody > tr > td {
  border: 0;
  border-left: 1px solid #eaeaea;
}
.table-columned > tbody > tr > th {
  border: 0;
}

/**====== Foo-table css end ======**/
/**====== Data Tables css end ======**/
/**  =====================
      Authentication css start
==========================  **/
.auth-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  min-width: 100%;
  min-height: 100vh;
}
.auth-wrapper a,
.auth-wrapper p > a {
  color: #111;
  font-weight: 600;
}
.auth-wrapper .btn-auth-gen .btn-icon {
  width: 140px;
  height: 140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 45px;
}
.auth-wrapper .btn-auth-gen .btn-icon small {
  font-size: 15px;
}
.auth-wrapper .input-group {
  background: transparent;
}
.auth-wrapper .card {
  margin-bottom: 0;
}
.auth-wrapper > div {
  z-index: 5;
}
.auth-wrapper .auth-content {
  position: relative;
  width: 390px;
  padding: 15px;
  z-index: 5;
}
.auth-wrapper .auth-content.multyform, .auth-wrapper .auth-content.subscribe {
  width: 750px;
}
@media only screen and (max-width: 768px) {
  .auth-wrapper {
    max-width: 360px;
  }
}
@media only screen and (max-width: 575px) {
  .auth-wrapper .card .card-body {
    padding: 30px 15px;
  }
}
.auth-wrapper .auth-icon {
  font-size: 30px;
}
.auth-wrapper .auth-icon:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auth-wrapper.offline {
  background-image: none;
}
.auth-wrapper.offline:before {
  display: none;
}
.auth-wrapper .auth-bg .r {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
}
.auth-wrapper .auth-bg .r:first-child {
  top: -100px;
  right: -100px;
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.auth-wrapper .auth-bg .r:last-child {
  left: -100px;
  bottom: -100px;
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.auth-wrapper .auth-bg .r.s {
  width: 20px;
  height: 20px;
}
.auth-wrapper .auth-bg .r.s:nth-child(2) {
  top: 150px;
  right: -150px;
  background: #04a9f5;
}
.auth-wrapper .auth-bg .r.s:nth-child(3) {
  left: -150px;
  bottom: 150px;
  background: #1de9b6;
}
.auth-wrapper .auth-bg .r:nth-child(odd) {
  -webkit-animation: floating 7s infinite;
          animation: floating 7s infinite;
}
.auth-wrapper .auth-bg .r:nth-child(even) {
  -webkit-animation: floating 9s infinite;
          animation: floating 9s infinite;
}

/* image varient start */
.aut-bg-img {
  /* background-image: url("../images/bg-images/bg4.jpg"); */
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.aut-bg-img-side p {
  line-height: 2;
}

.aut-bg-img .custom-select, .aut-bg-img .form-control,
.aut-bg-img-side .custom-select,
.aut-bg-img-side .form-control {
  background: transparent;
}

.auth-tabs .tab-content {
  overflow: hidden;
  position: relative;
}
.auth-tabs .tab-content .auth-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 150px;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
}

/* image varient start */
@-webkit-keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
            transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
            transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
@keyframes floating {
  from {
    -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
            transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
            transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }
}
/**====== Authentication css end ======**/
/* Button variants
 Easily pump out default styles, as well as :hover, :focus, :active,
 and disabled options for all buttons */
/**  =====================
      Button css start
==========================  **/
.btn-theme,
a.btn-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border-color: #1de1c2;
  border-radius: 0.25rem;
  padding: 11px 25px;
}
.btn-theme:active, .btn-theme:focus, .btn-theme:not(:disabled):not(.disabled):active,
a.btn-theme:active,
a.btn-theme:focus,
a.btn-theme:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-theme.active,
a.btn-theme.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-theme {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-outline-theme:active, .btn-outline-theme:focus, .btn-outline-theme:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme.active {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%) !important;
  color: #fff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 14px 25px;
}
.btn-theme2:active, .btn-theme2:focus, .btn-theme2:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-theme2.active {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-theme2 {
  background-image: #fff !important;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-outline-theme2:active, .btn-outline-theme2:focus, .btn-outline-theme2:not(:disabled):not(.disabled):active {
  background-image: #fff;
  color: #d6d6d6;
  border: 1px solid #eaeaea;
}
.btn-outline-theme2.active {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%) !important;
  color: #fff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid transparent;
}

.btn {
  padding: 10px 20px;
  border-radius: 0.25rem;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.btn > i {
  margin-right: 12px;
}
.btn.btn-icon, .btn.drp-icon {
  width: 45px;
  height: 45px;
  padding: 10px 12px;
}
.btn.btn-icon > i, .btn.drp-icon > i {
  margin-right: 0;
}
.btn.drp-icon.dropdown-toggle:after {
  display: none;
}
.btn.drp-icon + .dropdown-menu {
  margin-left: -10px;
}
.btn:active, .btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-square {
  border-radius: 0;
}
.btn.disabled {
  cursor: not-allowed;
  opacity: 0.55;
}
.btn-rounded {
  border-radius: 30px;
}

.btn-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.shadow-1 {
  -webkit-box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16);
          box-shadow: 0 7px 12px 0 rgba(62, 57, 107, 0.16);
}

.shadow-2 {
  -webkit-box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
          box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2);
}

.shadow-3 {
  -webkit-box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26);
          box-shadow: 0 14px 24px 0 rgba(62, 57, 107, 0.26);
}

.shadow-4 {
  -webkit-box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3);
          box-shadow: 0 16px 28px 0 rgba(62, 57, 107, 0.3);
}

.shadow-5 {
  -webkit-box-shadow: 0 20px 24px 0 rgba(62, 57, 107, 0.36);
          box-shadow: 0 20px 24px 0 rgba(62, 57, 107, 0.36);
}

/* Alternate buttons */
.btn-primary {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:hover {
  color: #fff;
  background-color: #038fcf;
  border-color: #0386c3;
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0386c3;
  border-color: #037eb6;
}

.btn-secondary {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #62747d;
  border-color: #5d6e76;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5d6e76;
  border-color: #57676f;
}

.btn-success {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:hover {
  color: #fff;
  background-color: #14cc9e;
  border-color: #13c095;
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #13c095;
  border-color: #12b58c;
}

.btn-info {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:hover {
  color: #fff;
  background-color: #1cb4e6;
  border-color: #18acdd;
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #18acdd;
  border-color: #17a3d1;
}

.btn-warning {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ecb50c;
  border-color: #e0ab0c;
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #e0ab0c;
  border-color: #d4a20b;
}

.btn-danger {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22012;
  border-color: #ea1b0d;
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1b0d;
  border-color: #de190c;
}

.btn-light {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:hover {
  color: #37474f;
  background-color: #dfdfdf;
  border-color: #d9d9d9;
}
.btn-light.disabled, .btn-light:disabled {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #37474f;
  background-color: #d9d9d9;
  border-color: #d2d2d2;
}

.btn-dark {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:hover {
  color: #fff;
  background-color: #273338;
  border-color: #222c31;
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #222c31;
  border-color: #1d2529;
}

/* outline buttons */
.btn-outline-primary {
  color: #04a9f5;
  background-color: transparent;
  background-image: none;
  border-color: #04a9f5;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #04a9f5;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}

.btn-outline-secondary {
  color: #748892;
  background-color: transparent;
  background-image: none;
  border-color: #748892;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #748892;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #748892;
  border-color: #748892;
}

.btn-outline-success {
  color: #1de9b6;
  background-color: transparent;
  background-image: none;
  border-color: #1de9b6;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #1de9b6;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1de9b6;
  border-color: #1de9b6;
}

.btn-outline-info {
  color: #3ebfea;
  background-color: transparent;
  background-image: none;
  border-color: #3ebfea;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #3ebfea;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #3ebfea;
  border-color: #3ebfea;
}

.btn-outline-warning {
  color: #f4c22b;
  background-color: transparent;
  background-image: none;
  border-color: #f4c22b;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f4c22b;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4c22b;
  border-color: #f4c22b;
}

.btn-outline-danger {
  color: #f44236;
  background-color: transparent;
  background-image: none;
  border-color: #f44236;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #f44236;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44236;
  border-color: #f44236;
}

.btn-outline-light {
  color: #f2f2f2;
  background-color: transparent;
  background-image: none;
  border-color: #f2f2f2;
}
.btn-outline-light:hover {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f2f2f2;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #37474f;
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.btn-outline-dark {
  color: #37474f;
  background-color: transparent;
  background-image: none;
  border-color: #37474f;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #37474f;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #37474f;
  border-color: #37474f;
}

/* glow buttons */
.btn-glow-primary {
  -webkit-box-shadow: 0 1px 6px 2px rgba(4, 169, 245, 0.56), 0 6px 11px 2px rgba(4, 169, 245, 0.2);
          box-shadow: 0 1px 6px 2px rgba(4, 169, 245, 0.56), 0 6px 11px 2px rgba(4, 169, 245, 0.2);
}
.btn-glow-primary:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(4, 169, 245, 0.56), 0 4px 9px 2px rgba(4, 169, 245, 0.1);
          box-shadow: 0 1px 4px 2px rgba(4, 169, 245, 0.56), 0 4px 9px 2px rgba(4, 169, 245, 0.1);
}
.btn-glow-primary:not(:disabled):not(.disabled).active, .btn-glow-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-primary.dropdown-toggle, .btn-glow-primary.active, .btn-glow-primary:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-secondary {
  -webkit-box-shadow: 0 1px 6px 2px rgba(116, 136, 146, 0.56), 0 6px 11px 2px rgba(116, 136, 146, 0.2);
          box-shadow: 0 1px 6px 2px rgba(116, 136, 146, 0.56), 0 6px 11px 2px rgba(116, 136, 146, 0.2);
}
.btn-glow-secondary:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(116, 136, 146, 0.56), 0 4px 9px 2px rgba(116, 136, 146, 0.1);
          box-shadow: 0 1px 4px 2px rgba(116, 136, 146, 0.56), 0 4px 9px 2px rgba(116, 136, 146, 0.1);
}
.btn-glow-secondary:not(:disabled):not(.disabled).active, .btn-glow-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-secondary.dropdown-toggle, .btn-glow-secondary.active, .btn-glow-secondary:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-success {
  -webkit-box-shadow: 0 1px 6px 2px rgba(29, 233, 182, 0.56), 0 6px 11px 2px rgba(29, 233, 182, 0.2);
          box-shadow: 0 1px 6px 2px rgba(29, 233, 182, 0.56), 0 6px 11px 2px rgba(29, 233, 182, 0.2);
}
.btn-glow-success:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(29, 233, 182, 0.56), 0 4px 9px 2px rgba(29, 233, 182, 0.1);
          box-shadow: 0 1px 4px 2px rgba(29, 233, 182, 0.56), 0 4px 9px 2px rgba(29, 233, 182, 0.1);
}
.btn-glow-success:not(:disabled):not(.disabled).active, .btn-glow-success:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-success.dropdown-toggle, .btn-glow-success.active, .btn-glow-success:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-info {
  -webkit-box-shadow: 0 1px 6px 2px rgba(62, 191, 234, 0.56), 0 6px 11px 2px rgba(62, 191, 234, 0.2);
          box-shadow: 0 1px 6px 2px rgba(62, 191, 234, 0.56), 0 6px 11px 2px rgba(62, 191, 234, 0.2);
}
.btn-glow-info:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(62, 191, 234, 0.56), 0 4px 9px 2px rgba(62, 191, 234, 0.1);
          box-shadow: 0 1px 4px 2px rgba(62, 191, 234, 0.56), 0 4px 9px 2px rgba(62, 191, 234, 0.1);
}
.btn-glow-info:not(:disabled):not(.disabled).active, .btn-glow-info:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-info.dropdown-toggle, .btn-glow-info.active, .btn-glow-info:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-warning {
  -webkit-box-shadow: 0 1px 6px 2px rgba(244, 194, 43, 0.56), 0 6px 11px 2px rgba(244, 194, 43, 0.2);
          box-shadow: 0 1px 6px 2px rgba(244, 194, 43, 0.56), 0 6px 11px 2px rgba(244, 194, 43, 0.2);
}
.btn-glow-warning:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(244, 194, 43, 0.56), 0 4px 9px 2px rgba(244, 194, 43, 0.1);
          box-shadow: 0 1px 4px 2px rgba(244, 194, 43, 0.56), 0 4px 9px 2px rgba(244, 194, 43, 0.1);
}
.btn-glow-warning:not(:disabled):not(.disabled).active, .btn-glow-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-warning.dropdown-toggle, .btn-glow-warning.active, .btn-glow-warning:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-danger {
  -webkit-box-shadow: 0 1px 6px 2px rgba(244, 66, 54, 0.56), 0 6px 11px 2px rgba(244, 66, 54, 0.2);
          box-shadow: 0 1px 6px 2px rgba(244, 66, 54, 0.56), 0 6px 11px 2px rgba(244, 66, 54, 0.2);
}
.btn-glow-danger:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(244, 66, 54, 0.56), 0 4px 9px 2px rgba(244, 66, 54, 0.1);
          box-shadow: 0 1px 4px 2px rgba(244, 66, 54, 0.56), 0 4px 9px 2px rgba(244, 66, 54, 0.1);
}
.btn-glow-danger:not(:disabled):not(.disabled).active, .btn-glow-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-danger.dropdown-toggle, .btn-glow-danger.active, .btn-glow-danger:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-light {
  -webkit-box-shadow: 0 1px 6px 2px rgba(242, 242, 242, 0.56), 0 6px 11px 2px rgba(242, 242, 242, 0.2);
          box-shadow: 0 1px 6px 2px rgba(242, 242, 242, 0.56), 0 6px 11px 2px rgba(242, 242, 242, 0.2);
}
.btn-glow-light:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(242, 242, 242, 0.56), 0 4px 9px 2px rgba(242, 242, 242, 0.1);
          box-shadow: 0 1px 4px 2px rgba(242, 242, 242, 0.56), 0 4px 9px 2px rgba(242, 242, 242, 0.1);
}
.btn-glow-light:not(:disabled):not(.disabled).active, .btn-glow-light:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-light.dropdown-toggle, .btn-glow-light.active, .btn-glow-light:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-glow-dark {
  -webkit-box-shadow: 0 1px 6px 2px rgba(55, 71, 79, 0.56), 0 6px 11px 2px rgba(55, 71, 79, 0.2);
          box-shadow: 0 1px 6px 2px rgba(55, 71, 79, 0.56), 0 6px 11px 2px rgba(55, 71, 79, 0.2);
}
.btn-glow-dark:hover {
  -webkit-box-shadow: 0 1px 4px 2px rgba(55, 71, 79, 0.56), 0 4px 9px 2px rgba(55, 71, 79, 0.1);
          box-shadow: 0 1px 4px 2px rgba(55, 71, 79, 0.56), 0 4px 9px 2px rgba(55, 71, 79, 0.1);
}
.btn-glow-dark:not(:disabled):not(.disabled).active, .btn-glow-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-glow-dark.dropdown-toggle, .btn-glow-dark.active, .btn-glow-dark:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/**====== Button css end ======**/
.swal-footer {
  text-align: center;
}

                           /**  =====================
      Alert css start
==========================  **/
/* Base styles */
.alert {
  position: relative;
  padding: 15px 20px;
  border-radius: 0;
}

/* Provide class for links that match alerts */
.alert-link {
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.alert-link:hover {
  text-decoration: underline;
}

.alert-dismissible {
  padding-right: 40px;
}
.alert-dismissible .close {
  padding: 14px 10px;
}

/* Alternate styles
 Generate contextual modifier classes for colorizing the alert. */
.alert-primary {
  color: #1c7aa5;
  border-color: #b9e7fc;
}
.alert-primary hr {
  border-top-color: #a1dffb;
}
.alert-primary .alert-link {
  color: #155a79;
}

.alert-secondary {
  color: #576972;
  border-color: #d8dee0;
}
.alert-secondary hr {
  border-top-color: #cad2d5;
}
.alert-secondary .alert-link {
  color: #414e55;
}

.alert-success {
  color: #299b85;
  border-color: #c0f9eb;
}
.alert-success hr {
  border-top-color: #a9f7e4;
}
.alert-success .alert-link {
  color: #1e7362;
}

.alert-info {
  color: #3b85a0;
  border-color: #c9edf9;
}
.alert-info hr {
  border-top-color: #b2e5f6;
}
.alert-info .alert-link {
  color: #2d667b;
}

.alert-warning {
  color: #99873c;
  border-color: #fceec4;
}
.alert-warning hr {
  border-top-color: #fbe7ac;
}
.alert-warning .alert-link {
  color: #74672e;
}

.alert-danger {
  color: #994442;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #753433;
}

.alert-light {
  color: #98a0a4;
  border-color: #fbfbfb;
}
.alert-light hr {
  border-top-color: #eeeeee;
}
.alert-light .alert-link {
  color: #7d878c;
}

.alert-dark {
  color: #37474f;
  border-color: #c7cbce;
}
.alert-dark hr {
  border-top-color: #b9bec2;
}
.alert-dark .alert-link {
  color: #222c31;
}

/**====== Alert css end ======**/
/**  =====================
      Breadcrumbs & Pagination css start
==========================  **/
/* Breadcrumbs */
.breadcrumb {
  background-color: #f4f7fa;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  color: #04a9f5;
}
.breadcrumb-item + .breadcrumb-item::before {
  /* content: $breadcrumb-divider; */
}
.breadcrumb-item.active {
  color: #111;
}

/* pagination */
.page-item.active .page-link {
  color: #fff;
  background-color: #04a9f5;
  border-color: #04a9f5;
}

/**====== Breadcrumbs & Pagination css end ======**/
/**  =====================
      Progress css start
==========================  **/
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 16px 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  height: 12px;
  font-size: 10px;
  background-color: #f4f7fa;
  border-radius: 0;
  overflow: visible;
}

.progress-bar {
  background-color: #04a9f5;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
}
.progress-bar.progress-c-blue {
  background: #04a9f5;
}
.progress-bar.progress-c-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.progress-bar.progress-c-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.progress-bar.progress-c-red {
  background: #f44236;
}
.progress-bar.progress-c-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.progress-bar.progress-c-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.progress-bar.progress-c-green {
  background: #1de9b6;
}
.progress-bar.progress-c-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.progress-bar.progress-c-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.progress-bar.progress-c-yellow {
  background: #f4c22b;
}
.progress-bar.progress-c-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.progress-bar.progress-c-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}
.progress-bar.progress-c-purple {
  background: #a389d4;
}
.progress-bar.progress-c-theme {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}
.progress-bar.progress-c-theme2 {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

/**====== Progress css end ======**/
/**  =====================
      tooltip css start
==========================  **/
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
}
.tooltip .arrow::before {
  content: "\6d";
  left: 0;
  font-family: "pct";
  font-size: 20px;
  line-height: 0.3;
  color: #000;
  border: none;
  text-shadow: 0 2px 3px rgba(220, 220, 220, 0.46);
}

.bs-tooltip-right .arrow::before {
  content: "\6a";
  left: -7px;
  top: 2.6px;
  border: none;
  text-shadow: -2px 0 3px rgba(220, 220, 220, 0.46);
}
.bs-tooltip-right .tooltip-inner {
  border-radius: 15px 20px 20px 15px;
}

.bs-tooltip-bottom .arrow::before {
  content: "\6c";
  border: none;
  text-shadow: 0 -2px 3px rgba(220, 220, 220, 0.46);
}

.bs-tooltip-left .arrow::before {
  content: "\6b";
  left: -8px;
  top: 3.6px;
  border: none;
  text-shadow: 2px 0 3px rgba(220, 220, 220, 0.46);
}
.bs-tooltip-left .tooltip-inner {
  border-radius: 20px 15px 15px 20px;
}

.tooltip-inner {
  border-radius: 20px;
  padding: 6px 15px;
  -webkit-box-shadow: 0 0 15px #dcdcdc;
          box-shadow: 0 0 15px #dcdcdc;
}

/**====== tooltip css end ======**/
/**  =====================
      tooltip css start
==========================  **/
.popover {
  top: 0;
  left: 0;
  border: none;
  -webkit-box-shadow: 0 0 15px #dcdcdc;
          box-shadow: 0 0 15px #dcdcdc;
  border-radius: 15px;
}
.popover .arrow::after {
  display: none;
}
.popover .arrow::before {
  content: "\6d";
  top: -1px;
  left: 0;
  font-family: "pct";
  font-size: 20px;
  line-height: 0.3;
  color: #fff;
  border: none;
  text-shadow: 0 2px 3px rgba(220, 220, 220, 0.7);
}
.popover .popover-header {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background: transparent;
  border-bottom-color: #f4f7fa;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  content: "\6d";
  text-shadow: 0 2px 3px rgba(220, 220, 220, 0.7);
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  content: "\6a";
  left: -4px;
  top: 2.6px;
  border: none;
  text-shadow: -2px 0 3px rgba(220, 220, 220, 0.46);
}

.bs-popover-bottom .arrow:before, .bs-popover-auto[x-placement^="bottom"] .arrow:before {
  content: "\6c";
  top: 3px;
  border: none;
  text-shadow: 0 -2px 3px rgba(220, 220, 220, 0.46);
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  content: "\6b";
  left: -8px;
  top: 3.6px;
  border: none;
  text-shadow: 2px 0 3px rgba(220, 220, 220, 0.46);
}

/**====== tooltip css end ======**/
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 10px 24px;
}
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  opacity: 0.7;
}

/* ======================== Tabs =============================== */
.nav-tabs {
  border-bottom: none;
}
.nav-tabs .nav-link {
  border: none;
  color: #888;
}
.nav-tabs .nav-link:hover {
  color: #04a9f5;
}
.nav-tabs .nav-link.disabled {
  opacity: 0.7;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none;
  color: #111;
  -webkit-box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.05);
}

/* =======================   Pills ================== */
.nav-pills {
  padding: 15px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}
.nav-pills .nav-link {
  border-radius: 5px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background: #04a9f5;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}

/* ==================== Justified variants ===================*/
.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

/* Tabbable tabs */
/* Hide tabbable panes to start, show them when `.active` */
.tab-content {
  padding: 35px 30px;
  background: #fff;
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

@media screen and (max-width: 480px) {
  .modal-footer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/**  =====================
      Chatting css start
==========================  **/
.header-chat,
.header-user-list {
  height: 100%;
  width: 350px;
  position: fixed;
  top: 0;
  right: -350px;
  border-radius: 0;
  z-index: 1030;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.header-chat .main-friend-cont,
.header-user-list .main-friend-cont {
  height: calc(100vh - 66px);
}
.header-chat .h-list-header,
.header-user-list .h-list-header {
  padding: 15px;
  border-bottom: 1px solid #f1f1f1;
}
.header-chat .h-list-body,
.header-user-list .h-list-body {
  padding: 20px 0;
}
.header-chat.open,
.header-user-list.open {
  -webkit-box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
          box-shadow: 0 1px 10px 0 rgba(69, 90, 100, 0.2);
  right: 0;
}

.header-user-list.open .h-close-text {
  position: absolute;
  top: 111px;
  left: -99px;
}
.header-user-list.open .h-close-text i {
  position: absolute;
  top: 23px;
  left: 73px;
  font-size: 25px;
  z-index: 1003;
  color: #1de9b6;
}
.header-user-list.open .h-close-text:after {
  content: "\61";
  font-family: "pct";
  z-index: 1001;
  font-size: 150px;
  line-height: 0;
  color: #fff;
  position: absolute;
  top: 35px;
  left: 6px;
  text-shadow: -4px 0 7px rgba(69, 90, 100, 0.12);
}
.header-user-list.open.msg-open:after {
  color: rgba(4, 169, 245, 0.1);
}

.h-list-header .form-control:active, .h-list-header .form-control:focus, .h-list-header .form-control:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}

.h-list-body {
  position: relative;
}
.h-list-body .userlist-box {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px 20px;
  position: relative;
}
.h-list-body .userlist-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 1px;
  background: #f3f4f9;
}
.h-list-body .userlist-box.active {
  background: #e0f5fe;
}
.h-list-body .userlist-box .media-left {
  padding-right: 10px;
}
.h-list-body .userlist-box .media-object {
  width: 50px;
  display: inline-block;
}
.h-list-body .userlist-box .chat-header {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.h-list-body .userlist-box .chat-header small {
  margin-top: 5px;
  font-size: 90%;
}
.h-list-body .userlist-box .live-status {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 35px;
  right: 20px;
  border-radius: 100%;
  color: #fff;
  padding: 2px 0;
  text-align: center;
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.header-chat .h-list-header {
  text-align: center;
  position: relative;
}
.header-chat .h-list-header h6 {
  margin: 5px 0;
}
.header-chat .h-list-header .h-back-user-list {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 20px;
}
.header-chat .main-chat-cont {
  height: calc(100vh - 166px);
}
.header-chat .h-list-body {
  height: 100%;
  background: #e0f5fe;
}
.header-chat .h-list-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 20px 15px;
  z-index: 10;
  background: #e0f5fe;
}
.header-chat .h-list-footer .input-group {
  background: #fff;
  border: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 7px;
  border-radius: 20px 0 10px 20px;
  width: calc(100% - 40px);
}
.header-chat .h-list-footer .input-group .form-control,
.header-chat .h-list-footer .input-group .input-group-text {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
}
.header-chat .h-list-footer .input-group .btn-send:active, .header-chat .h-list-footer .input-group .btn-send:focus, .header-chat .h-list-footer .input-group .btn-send:hover,
.header-chat .h-list-footer .input-group .form-control:active,
.header-chat .h-list-footer .input-group .form-control:focus,
.header-chat .h-list-footer .input-group .form-control:hover {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.header-chat .h-list-footer .input-group .btn-attach {
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
}
.header-chat .h-list-footer .input-group .btn-attach > i {
  margin-right: 0;
}
.header-chat .h-list-footer .input-group .btn-send {
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  position: absolute;
  right: -45px;
  top: 2px;
  z-index: 99;
}
.header-chat .h-list-footer .input-group .btn-send i {
  margin-right: 0;
}
.header-chat .h-list-footer .input-group .btn-send .input-group-text {
  color: #fff;
}
.header-chat .h-list-footer .input-group .form-control {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 0;
}
.header-chat .h-list-footer .input-group:after {
  content: "\67";
  font-family: "pct";
  z-index: 1001;
  font-size: 35px;
  line-height: 0;
  color: #fff;
  position: absolute;
  top: 18px;
  right: -23px;
  text-shadow: 4px 10px 20px rgba(0, 0, 0, 0.1);
}

.h-list-body .chat-messages {
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
.h-list-body .chat-messages .photo-table {
  padding-right: 15px;
}
.h-list-body .chat-messages .photo-table img {
  display: inline-block;
  width: 50px;
  margin-bottom: 5px;
}
.h-list-body .chat-messages .chat-menu-content > div,
.h-list-body .chat-messages .chat-menu-reply > div {
  position: relative;
  overflow: visible;
  display: inline-block;
}
.h-list-body .chat-messages .chat-menu-content > div .chat-cont,
.h-list-body .chat-messages .chat-menu-reply > div .chat-cont {
  padding: 15px 20px;
}
.h-list-body .chat-messages .chat-menu-content .chat-time,
.h-list-body .chat-messages .chat-menu-reply .chat-time {
  margin: 9px 8px 0 10px;
}
.h-list-body .chat-messages .chat-menu-reply {
  text-align: right;
}
.h-list-body .chat-messages .chat-menu-reply > div p {
  background: #fff;
  border-radius: 4px;
  margin-bottom: 4px;
  margin-right: 25px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.h-list-body .chat-messages .chat-menu-reply > div p:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.h-list-body .chat-messages .chat-menu-reply > div p:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0;
}
.h-list-body .chat-messages .chat-menu-reply > div:before {
  content: "\66";
  font-family: "pct";
  z-index: 5;
  font-size: 30px;
  line-height: 0;
  color: #fff;
  position: absolute;
  bottom: 19px;
  right: 5px;
  text-shadow: 7px 10px 20px rgba(0, 0, 0, 0.1);
}
.h-list-body .chat-messages .chat-menu-content > div p {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  color: #fff;
  border-radius: 4px;
  margin-bottom: 4px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}
.h-list-body .chat-messages .chat-menu-content > div p:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}
.h-list-body .chat-messages .chat-menu-content > div p:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.h-list-body .chat-messages .chat-menu-content > div:before {
  content: "\68";
  font-family: "pct";
  z-index: 1001;
  font-size: 30px;
  line-height: 0;
  color: #1ec9de;
  position: absolute;
  top: 15px;
  left: -20px;
  text-shadow: -4px 4px 10px rgba(0, 0, 0, 0.15);
}

/* massage page start */
.msg-card .msg-user-list {
  height: calc(100vh - 300px);
}
.msg-card .msg-user-chat {
  background: #e0f5fe;
  height: calc(100vh - 330px);
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
.msg-card .msg-block > .row > div:before {
  content: "";
  width: 1px;
  height: 100%;
  background: #f1f1f1;
  position: absolute;
  top: 0;
}
.msg-card .msg-block > .row > div:first-child:before {
  right: 0;
}
.msg-card .msg-block > .row > div:last-child:before {
  left: -1px;
}

.main-friend-chat {
  padding-bottom: 15px;
}

@media screen and (max-width: 991px) {
  .msg-card .msg-block > .row > div:before {
    background: none;
  }
}
/* massage page end */
/**====== Chat css end ======**/
/**  =====================
      Prism css start
==========================  **/
pre[class*="language-"] code {
  font-size: 14px;
}
pre[class*="language-"] [class*="language-"],
pre[class*="language-"] .language-markup {
  background-color: transparent;
  display: block;
  padding: 10px 15px;
}

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers-rows > span:before {
  content: counter(linenumber);
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

div.code-toolbar {
  position: relative;
}

div.code-toolbar > .toolbar {
  position: absolute;
  top: 0.3em;
  right: 0.2em;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

div.code-toolbar:hover > .toolbar {
  opacity: 1;
}

div.code-toolbar > .toolbar .toolbar-item {
  display: inline-block;
}

div.code-toolbar > .toolbar a {
  cursor: pointer;
}

div.code-toolbar > .toolbar button {
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible;
}

div.code-toolbar > .toolbar a,
div.code-toolbar > .toolbar button,
div.code-toolbar > .toolbar span {
  color: #bbb;
  font-size: 0.8em;
  padding: 0 0.5em;
  background: #f5f2f0;
  background: rgba(224, 224, 224, 0.2);
  -webkit-box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
}

div.code-toolbar > .toolbar a:focus,
div.code-toolbar > .toolbar a:hover,
div.code-toolbar > .toolbar button:focus,
div.code-toolbar > .toolbar button:hover,
div.code-toolbar > .toolbar span:focus,
div.code-toolbar > .toolbar span:hover {
  color: inherit;
  text-decoration: none;
}

/**====== Prism css end ======**/
/**  =====================
      Toolbar css start
==========================  **/
.tool-bottom .tool-item:first-child,
.tool-top .tool-item:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.tool-bottom .tool-item:last-child,
.tool-top .tool-item:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tool-left .tool-item:first-child,
.tool-left .tool-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.tool-left .tool-item:last-child,
.tool-left .tool-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.toolbar-primary .tool-item {
  background: #04a9f5;
}
.toolbar-primary .tool-item.selected, .toolbar-primary .tool-item:hover {
  background: #0386c3;
}
.toolbar-primary.tool-top .arrow {
  border-color: #04a9f5 transparent transparent;
}
.toolbar-primary.tool-bottom .arrow {
  border-color: transparent transparent #04a9f5;
}
.toolbar-primary.tool-left .arrow {
  border-color: transparent transparent transparent #04a9f5;
}
.toolbar-primary.tool-right .arrow {
  border-color: transparent #04a9f5 transparent transparent;
}

.btn-toolbar-primary.pressed {
  background-color: #04a9f5;
}

.toolbar-warning .tool-item {
  background: #f4c22b;
}
.toolbar-warning .tool-item.selected, .toolbar-warning .tool-item:hover {
  background: #e0ab0c;
}
.toolbar-warning.tool-top .arrow {
  border-color: #f4c22b transparent transparent;
}
.toolbar-warning.tool-bottom .arrow {
  border-color: transparent transparent #f4c22b;
}
.toolbar-warning.tool-left .arrow {
  border-color: transparent transparent transparent #f4c22b;
}
.toolbar-warning.tool-right .arrow {
  border-color: transparent #f4c22b transparent transparent;
}

.btn-toolbar-warning.pressed {
  background-color: #f4c22b;
}

.toolbar-light .tool-item {
  background: #d6d6d6;
}
.toolbar-light .tool-item.selected, .toolbar-light .tool-item:hover {
  background: #bdbdbd;
}
.toolbar-light.tool-top .arrow {
  border-color: #d6d6d6 transparent transparent;
}
.toolbar-light.tool-bottom .arrow {
  border-color: transparent transparent #d6d6d6;
}
.toolbar-light.tool-left .arrow {
  border-color: transparent transparent transparent #d6d6d6;
}
.toolbar-light.tool-right .arrow {
  border-color: transparent #d6d6d6 transparent transparent;
}

.btn-toolbar-light.pressed {
  background-color: #d6d6d6;
}

.toolbar-danger .tool-item {
  background: #f44236;
}
.toolbar-danger .tool-item.selected, .toolbar-danger .tool-item:hover {
  background: #ea1b0d;
}
.toolbar-danger.tool-top .arrow {
  border-color: #f44236 transparent transparent;
}
.toolbar-danger.tool-bottom .arrow {
  border-color: transparent transparent #f44236;
}
.toolbar-danger.tool-left .arrow {
  border-color: transparent transparent transparent #f44236;
}
.toolbar-danger.tool-right .arrow {
  border-color: transparent #f44236 transparent transparent;
}

.btn-toolbar-danger.pressed {
  background-color: #f44236;
}

.toolbar-success .tool-item {
  background: #1de9b6;
}
.toolbar-success .tool-item.selected, .toolbar-success .tool-item:hover {
  background: #13c095;
}
.toolbar-success.tool-top .arrow {
  border-color: #1de9b6 transparent transparent;
}
.toolbar-success.tool-bottom .arrow {
  border-color: transparent transparent #1de9b6;
}
.toolbar-success.tool-left .arrow {
  border-color: transparent transparent transparent #1de9b6;
}
.toolbar-success.tool-right .arrow {
  border-color: transparent #1de9b6 transparent transparent;
}

.btn-toolbar-success.pressed {
  background-color: #1de9b6;
}

.toolbar-dark .tool-item {
  background: #37474f;
}
.toolbar-dark .tool-item.selected, .toolbar-dark .tool-item:hover {
  background: #222c31;
}
.toolbar-dark.tool-top .arrow {
  border-color: #37474f transparent transparent;
}
.toolbar-dark.tool-bottom .arrow {
  border-color: transparent transparent #37474f;
}
.toolbar-dark.tool-left .arrow {
  border-color: transparent transparent transparent #37474f;
}
.toolbar-dark.tool-right .arrow {
  border-color: transparent #37474f transparent transparent;
}

.btn-toolbar-dark.pressed {
  background-color: #37474f;
}

.toolbar-info .tool-item {
  background: #3ebfea;
}
.toolbar-info .tool-item.selected, .toolbar-info .tool-item:hover {
  background: #18acdd;
}
.toolbar-info.tool-top .arrow {
  border-color: #3ebfea transparent transparent;
}
.toolbar-info.tool-bottom .arrow {
  border-color: transparent transparent #3ebfea;
}
.toolbar-info.tool-left .arrow {
  border-color: transparent transparent transparent #3ebfea;
}
.toolbar-info.tool-right .arrow {
  border-color: transparent #3ebfea transparent transparent;
}

.btn-toolbar-info.pressed {
  background-color: #3ebfea;
}

/**====== Toolbar css end ======**/
/**  =====================
      Switches css start
==========================  **/
.switch input[type=checkbox] {
  opacity: 0;
  position: absolute;
}
.switch input[type=checkbox] + .cr {
  position: relative;
  display: inline-block;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  height: 20px;
  width: 35px;
  border: 1px solid #e9eaec;
  border-radius: 60px;
  cursor: pointer;
  z-index: 0;
  top: 12px;
}
.switch input[type=checkbox] + .cr:after, .switch input[type=checkbox] + .cr:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}
.switch input[type=checkbox] + .cr:before {
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 20px;
  width: 35px;
  border-radius: 30px;
}
.switch input[type=checkbox] + .cr:after {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #f7f7f7;
  height: 19px;
  width: 19px;
  border-radius: 60px;
}
.switch input[type=checkbox]:checked + .cr:before {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
}
.switch input[type=checkbox]:checked + .cr:after {
  left: 16px;
}
.switch input[type=checkbox]:disabled + label {
  opacity: 0.5;
  -webkit-filter: grayscale(0.4);
          filter: grayscale(0.4);
  cursor: not-allowed;
}
.switch.switch-primary input[type=checkbox]:checked + .cr:before {
  background: #04a9f5;
}
.switch.switch-danger input[type=checkbox]:checked + .cr:before {
  background: #f44236;
}
.switch.switch-success input[type=checkbox]:checked + .cr:before {
  background: #1de9b6;
}
.switch.switch-warning input[type=checkbox]:checked + .cr:before {
  background: #f4c22b;
}
.switch.switch-info input[type=checkbox]:checked + .cr:before {
  background: #3ebfea;
}
.switch.switch-alternative input[type=checkbox]:checked + .cr:before {
  background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

/**====== Switches css end ======**/
/**  =====================
      wizard css start
==========================  **/
/*  material wizard start */
.wizard-container {
  z-index: 3;
}
.wizard-container .wizard-navigation {
  position: relative;
}

.wizard-card .moving-tab {
  position: absolute;
  text-align: center;
  padding: 16px 12px;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-font-smoothing: subpixel-antialiased;
  top: -6px;
  left: 0;
  border-radius: 4px;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: 500;
}
.wizard-card[data-color="wizard-primary"] .moving-tab {
  background-color: #04a9f5;
  -webkit-box-shadow: 0 16px 26px -10px rgba(4, 169, 245, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(4, 169, 245, 0.2);
          box-shadow: 0 16px 26px -10px rgba(4, 169, 245, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(4, 169, 245, 0.2);
}
.wizard-card[data-color="wizard-danger"] .moving-tab {
  background-color: #f44236;
  -webkit-box-shadow: 0 16px 26px -10px rgba(244, 66, 54, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 66, 54, 0.2);
          box-shadow: 0 16px 26px -10px rgba(244, 66, 54, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 66, 54, 0.2);
}
.wizard-card[data-color="wizard-success"] .moving-tab {
  background-color: #1de9b6;
  -webkit-box-shadow: 0 16px 26px -10px rgba(29, 233, 182, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(29, 233, 182, 0.2);
          box-shadow: 0 16px 26px -10px rgba(29, 233, 182, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(29, 233, 182, 0.2);
}
.wizard-card[data-color="wizard-warning"] .moving-tab {
  background-color: #f4c22b;
  -webkit-box-shadow: 0 16px 26px -10px rgba(244, 194, 43, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 194, 43, 0.2);
          box-shadow: 0 16px 26px -10px rgba(244, 194, 43, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 194, 43, 0.2);
}
.wizard-card[data-color="wizard-info"] .moving-tab {
  background-color: #3ebfea;
  -webkit-box-shadow: 0 16px 26px -10px rgba(62, 191, 234, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(62, 191, 234, 0.2);
          box-shadow: 0 16px 26px -10px rgba(62, 191, 234, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(62, 191, 234, 0.2);
}
.wizard-card .wizard-footer .checkbox {
  margin-top: 16px;
}
.wizard-card .disabled {
  display: none;
}

.nav-pills > li + li {
  margin-left: 0;
}
.nav-pills > li > a {
  border: 0;
  border-radius: 0;
  line-height: 18px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555555;
  position: relative;
  display: block;
  padding: 11px 15px;
}
.nav-pills > li.active > a {
  background-color: inherit;
}
.nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: inherit;
}
.nav-pills > li > a:focus, .nav-pills > li > a:hover {
  background-color: inherit;
}
.nav-pills > li i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}

/* material wizard end */
/* Smart wizard 4 start */
.sw-theme-default {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.sw-theme-default > ul.step-anchor > li a,
.sw-theme-default > ul.step-anchor > li a > h6,
.sw-theme-default > ul.step-anchor > li a:hover {
  color: #eaeaea;
}
.sw-theme-default > ul.step-anchor > li.done a > h6 {
  color: initial;
}
.sw-theme-default > ul.step-anchor > li.active a,
.sw-theme-default > ul.step-anchor > li.active a h6 {
  color: #04a9f5;
}
.sw-theme-default > ul.step-anchor > li > a.nav-link:after {
  background: #04a9f5;
}
.sw-theme-default > ul.step-anchor > li.done > a.nav-link:after {
  background: #eaeaea;
}
.sw-theme-default .step-anchor,
.sw-theme-default .sw-container {
  background: #fff;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}
.sw-theme-default .step-anchor {
  margin-bottom: 30px;
}
.sw-theme-default .step-anchor li > a {
  padding: 15px 25px;
}
.sw-theme-default .sw-container .step-content {
  padding: 35px 30px;
}
.sw-theme-default .sw-toolbar {
  padding: 10px;
  background: transparent;
}
.sw-theme-default .step-content + .sw-toolbar {
  padding-top: 0;
}

.sw-theme-arrows .sw-container, .sw-theme-circles .sw-container, .sw-theme-dots .sw-container {
  background: #fff;
  -webkit-box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}
.sw-theme-arrows .sw-container .step-content, .sw-theme-circles .sw-container .step-content, .sw-theme-dots .sw-container .step-content {
  padding: 35px 30px;
}
.sw-theme-arrows .sw-toolbar-bottom, .sw-theme-circles .sw-toolbar-bottom, .sw-theme-dots .sw-toolbar-bottom {
  padding: 10px;
}
.sw-theme-arrows .step-anchor, .sw-theme-circles .step-anchor, .sw-theme-dots .step-anchor {
  margin-bottom: 30px;
}

.sw-theme-dots > ul.step-anchor > li > a:after {
  left: 42%;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .sw-theme-dots > ul.step-anchor > li > a:after {
    top: -42px;
    left: -40px;
  }

  .sw-theme-arrows > ul.step-anchor > li > a {
    padding-right: 15px;
  }
}
.sw-vertical-left,
.sw-vertical-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
@media (min-width: 768px) {
  .sw-vertical-left.sw-main,
  .sw-vertical-right.sw-main {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .sw-vertical-left .step-anchor,
  .sw-vertical-right .step-anchor {
    -ms-flex-item-align: start;
        align-self: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-width: 200px;
  }
  .sw-vertical-left .step-anchor li,
  .sw-vertical-right .step-anchor li {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }
}
@media (max-width: 767px) {
  .sw-vertical-left.sw-main,
  .sw-vertical-right.sw-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 768px) {
  .sw-vertical-left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .sw-vertical-left .sw-done-icon,
  .sw-vertical-left .sw-icon,
  .sw-vertical-left .sw-number {
    left: 2rem;
  }
  .sw-vertical-left .step-anchor {
    margin-right: 30px;
  }
}

@media (min-width: 768px) {
  .sw-vertical-right {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .sw-vertical-right .sw-done-icon,
  .sw-vertical-right .sw-icon,
  .sw-vertical-right .sw-number {
    right: 2rem;
  }
  .sw-vertical-right .step-anchor {
    margin-left: 30px;
  }
}
@media (max-width: 767px) {
  .sw-vertical-right .step-anchor {
    margin: 30px 0 0;
  }
}

/*  Smart wizard 4 end */
/**====== wizard css end ======**/
/**  =====================
      Chart css start
==========================  **/
.nvtooltip {
  position: fixed !important;
}

.peity-chart + .peity {
  width: 100%;
  height: 250px;
}

/* Radial Chart Start */
.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  margin-bottom: 20px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}
.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
  content: attr(data-label);
  background-color: #fff;
  z-index: 55;
}
.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}
.radial-bar > img {
  z-index: 102;
}
.radial-bar.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-55 {
  background-image: linear-gradient(288deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-60 {
  background-image: linear-gradient(306deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-65 {
  background-image: linear-gradient(324deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-70 {
  background-image: linear-gradient(342deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-80 {
  background-image: linear-gradient(378deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-85 {
  background-image: linear-gradient(396deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-90 {
  background-image: linear-gradient(414deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-95 {
  background-image: linear-gradient(432deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #04a9f5), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #04a9f5), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #04a9f5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #04a9f5), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-55 {
  background-image: linear-gradient(288deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-60 {
  background-image: linear-gradient(306deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-65 {
  background-image: linear-gradient(324deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-70 {
  background-image: linear-gradient(342deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #04a9f5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #04a9f5), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-80 {
  background-image: linear-gradient(378deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-85 {
  background-image: linear-gradient(396deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-90 {
  background-image: linear-gradient(414deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-95 {
  background-image: linear-gradient(432deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-primary.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #04a9f5), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #04a9f5), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #04a9f5 50%, transparent 50%, transparent), linear-gradient(270deg, #04a9f5 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #f44236), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #f44236), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #f44236), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f44236), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-55 {
  background-image: linear-gradient(288deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-60 {
  background-image: linear-gradient(306deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-65 {
  background-image: linear-gradient(324deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-70 {
  background-image: linear-gradient(342deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #f44236), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f44236), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-80 {
  background-image: linear-gradient(378deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-85 {
  background-image: linear-gradient(396deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-90 {
  background-image: linear-gradient(414deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-95 {
  background-image: linear-gradient(432deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-danger.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #f44236), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f44236), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #f44236 50%, transparent 50%, transparent), linear-gradient(270deg, #f44236 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-55 {
  background-image: linear-gradient(288deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-60 {
  background-image: linear-gradient(306deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-65 {
  background-image: linear-gradient(324deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-70 {
  background-image: linear-gradient(342deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-80 {
  background-image: linear-gradient(378deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-85 {
  background-image: linear-gradient(396deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-90 {
  background-image: linear-gradient(414deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-95 {
  background-image: linear-gradient(432deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-success.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #1de9b6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #1de9b6), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #1de9b6 50%, transparent 50%, transparent), linear-gradient(270deg, #1de9b6 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #f4c22b), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #f4c22b), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #f4c22b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f4c22b), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-55 {
  background-image: linear-gradient(288deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-60 {
  background-image: linear-gradient(306deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-65 {
  background-image: linear-gradient(324deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-70 {
  background-image: linear-gradient(342deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #f4c22b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f4c22b), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-80 {
  background-image: linear-gradient(378deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-85 {
  background-image: linear-gradient(396deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-90 {
  background-image: linear-gradient(414deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-95 {
  background-image: linear-gradient(432deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-warning.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4c22b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #f4c22b), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #f4c22b 50%, transparent 50%, transparent), linear-gradient(270deg, #f4c22b 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-0 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #3ebfea), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(90deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-5 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(108deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-10 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(126deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-15 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(144deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-20 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(162deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-25 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #d6d6d6), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #3ebfea), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(180deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-30 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(198deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-35 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(216deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-40 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(234deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-45 {
  background-image: linear-gradient(90deg, #d6d6d6 50%, transparent 50%, transparent), linear-gradient(252deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-50 {
  background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #3ebfea), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #3ebfea), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(270deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-55 {
  background-image: linear-gradient(288deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-60 {
  background-image: linear-gradient(306deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-65 {
  background-image: linear-gradient(324deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-70 {
  background-image: linear-gradient(342deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-75 {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #3ebfea), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #3ebfea), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(360deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-80 {
  background-image: linear-gradient(378deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-85 {
  background-image: linear-gradient(396deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-90 {
  background-image: linear-gradient(414deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-95 {
  background-image: linear-gradient(432deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}
.radial-bar.radial-bar-info.radial-bar-100 {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #3ebfea), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #3ebfea), color-stop(50%, #d6d6d6), to(#d6d6d6));
  background-image: linear-gradient(450deg, #3ebfea 50%, transparent 50%, transparent), linear-gradient(270deg, #3ebfea 50%, #d6d6d6 50%, #d6d6d6);
}

.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}
.radial-bar-lg > img, .radial-bar-lg:after {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar-sm {
  width: 60px;
  height: 60px;
  font-size: 12px;
}
.radial-bar-sm > img, .radial-bar-sm:after {
  width: 40px;
  height: 40px;
  margin-left: 10px;
  margin-top: 10px;
  line-height: 42px;
}

.radial-bar-xs {
  width: 35px;
  height: 35px;
  font-size: 10px;
}
.radial-bar-xs > img, .radial-bar-xs:after {
  width: 25px;
  height: 25px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 25px;
}

/* Radial Chart End */
/**====== Chart css end ======**/
/**  =====================
      Icon layouts css start
==========================  **/
.i-main .i-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 70px;
  height: 70px;
  margin: 5px;
  border: 1px solid #f1f1f1;
  position: relative;
  cursor: pointer;
}
.i-main .i-block i {
  font-size: 30px;
}
.i-main .i-block label {
  margin-bottom: 0;
  display: none;
}
.i-main .i-block span.ic-badge {
  position: absolute;
  bottom: 0;
  right: 0;
}
.i-main .i-block .flag-icon-background {
  width: 40px;
  height: 40px;
}

/**====== Icon layouts css end ======**/
/**  =====================
      Calendar css start
==========================  **/
.external-events .fc-event {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 5px;
  background: #04a9f5;
  border-color: #04a9f5;
}

.calendar a:not([href]):not([tabindex]), .calendar a:not([href]):not([tabindex]):focus, .calendar a:not([href]):not([tabindex]):hover {
  color: #fff;
}
.calendar .fc-event,
.calendar .fc-event:hover {
  color: #fff;
  cursor: move;
  padding: 5px 18px;
  margin-top: 2px;
  background: #04a9f5;
  border-color: #04a9f5;
}

/**====== Calendar css end ======**/
/**  =====================
      File Upload css start
==========================  **/
.dropzone {
  min-height: 150px;
  border: 1px solid rgba(42, 42, 42, 0.05);
  background: rgba(204, 204, 204, 0.15);
  padding: 20px;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px 0 rgba(43, 43, 43, 0.1);
          box-shadow: inset 0 0 5px 0 rgba(43, 43, 43, 0.1);
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable * {
  cursor: default;
}
.dropzone.dz-clickable .dz-message {
  cursor: pointer;
}
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-message {
  text-align: center;
  margin: 4em 0;
  color: #888;
  font-size: 18px;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0.5rem;
  min-height: 100px;
  -webkit-box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 13px 6px -8px rgba(0, 0, 0, 0.3), 0 0 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 5px;
  background: #999;
  background: -webkit-gradient(linear, left top, left bottom, from(#eee), to(#ddd));
  background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-image-preview {
  background: white;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
          filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 5px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}
.dropzone .dz-preview .dz-image img {
  display: block;
  display: block;
  padding: 4px;
  width: 100%;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
          animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
          animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-error-mark,
.dropzone .dz-preview .dz-success-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-error-mark svg,
.dropzone .dz-preview .dz-success-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
          animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 5px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: -webkit-gradient(linear, left top, left bottom, from(#666), to(#444));
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 5px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #f44236;
  background: -webkit-gradient(linear, left top, left bottom, from(#f44236), to(#f44236));
  background: linear-gradient(to bottom, #f44236, #f44236);
  padding: 0.5em 1.2em;
  color: white;
}
.dropzone .dz-preview .dz-error-message:after {
  content: '';
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #f44236;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  30%,
    70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
}

@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  30%,
    70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
/**====== File Upload css end ======**/


.fixed-button {
	position: fixed;
	bottom: -50px;
	right: 30px;
	opacity: 0;
	z-index: 9;
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	border-radius: 0.25rem
}

.fixed-button .btn {
	margin: 0;
	background: #79b530;
	border: none;
	border-bottom: 2px solid #5e8d25;
	text-transform: capitalize;
	font-weight: bold;
	font-size: 16px
}

.fixed-button .btn:hover {
	background: #79b530;
	border: none;
	border-bottom: 2px solid #5e8d25
}

.fixed-button:hover {
	-webkit-animation: none;
	animation: none
}

.fixed-button.active {
	bottom: 50px;
	opacity: 1
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(121, 181, 48, 0.3);
		box-shadow: 0 0 0 0 rgba(121, 181, 48, 0.3)
	}
	70% {
		-webkit-box-shadow: 0 0 0 20px rgba(4, 169, 245, 0);
		box-shadow: 0 0 0 20px rgba(4, 169, 245, 0)
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(4, 169, 245, 0);
		box-shadow: 0 0 0 0 rgba(4, 169, 245, 0)
	}
}

@keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(121, 181, 48, 0.3);
		box-shadow: 0 0 0 0 rgba(121, 181, 48, 0.3)
	}
	70% {
		-webkit-box-shadow: 0 0 0 20px rgba(4, 169, 245, 0);
		box-shadow: 0 0 0 20px rgba(4, 169, 245, 0)
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(4, 169, 245, 0);
		box-shadow: 0 0 0 0 rgba(4, 169, 245, 0)
	}
}

.card-media {
  border-radius: 15px;
  padding: 10px 0 !important;
}

.media-video iframe {
  height: 18rem;
}

.media-imgs {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 0.75rem;
  padding-bottom: 1rem;
}

.media-imgs a {
  position: relative;
  width: 40%;
  height: 7rem;
  text-decoration: none;
  color: #000;
  background-image: url('https://public.onlyfans.com/files/thumbs/w760/i/it/itx/itxhydbpkz0l8jnqscukr0i7m40mtw1q1669477031/279710041/header.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.media-imgs a:hover {
  color: #000;
  text-decoration: none;
}

.widget-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
}

.bg-trs {
  background-color: transparent;
}

.widgetIcon {
  font-size: 30px;
  cursor: pointer;
  padding: .25rem;
  border-radius: 9999px;
}

.widget-img {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 2rem;
  left: .25rem;
  border-radius: 9999px;
  border: 1px solid;
  overflow: hidden;
}

.widget-img img {
  width: 100%;
  height: 100%;
}

.widget-bottom {
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.widget-bottom p {
  font-weight: 600;
}

.plus {
  background-color: lightblue;
width: 36px;
height: 36px;
padding: 5px;
text-align: center;

}